import React from "react"
import { List, Datagrid, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"
import StandardList from "components/StandardList"
export const AccountList = props => (
  <StandardList {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="company" />
      <TextField source="orgnr" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="email" />
      <DateTimeField source="created" />
    </Datagrid>
  </StandardList>
)

export default AccountList
