// in AccountBulkSubscriptionButton.js
import IconContentAdd from "@material-ui/icons/Add"
import { priceFormater } from 'components/PriceField'
import React from "react"
import {
  Button, Create,
  ReferenceInput,
  SelectInput, SimpleForm,
  TextInput,
  useRefresh,
  useUnselectAll
} from "react-admin"
import RpcFormModal, { cleanProps, useRpcFormModal } from "RpcFormModal"

const optionRenderer = choice => <span>{choice.offer_name} - {priceFormater(choice.amount_calculated)}</span>

const AccountBulkSubscriptionButton = (props) => {

  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record = {id: 1}, resource, selectedIds } = props

  if (!record || !record.id) return null

  const resource_path = `account/${record.id}/subscription`
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    unselectAll(resource)
    setModalOpen(false)
    refresh()
  }
  const account_id = parseInt(record.id)

  return (
    <>
      <Button onClick={toggle} label="ra.action.add_subscriptions">
        <IconContentAdd />
      </Button>
      <RpcFormModal modalOpen={modalOpen} toggle={toggle} label={`Ny tjenste for ${selectedIds.length}  bruker.`}>
        <Create {...rest} resource={resource_path} record={null} >
          <SimpleForm redirect={redirect} initialValues={ {selectedIds } }>
            <ReferenceInput source="service_id" reference="service_summary" allowEmpty filter={{account_id}}>
              <SelectInput optionText={optionRenderer}/>
            </ReferenceInput>
            <TextInput source="reference" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default AccountBulkSubscriptionButton
