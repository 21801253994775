
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import UserEdit from "./UserEdit"
import UserTabs from "./UserTabs"

const sanitizeShowProps = ({hasEdit, hasShow, hasList, hasCreate, ...rest}) => rest
const sanitizeEditProps = ({hasList, hasShow, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <UserEdit {...sanitizeEditProps(props)} />
    <UserTabs {...sanitizeShowProps(props)} hasEdit={false} />
  </>
)
