import PageFieldset from "components/PageFieldset"
import React from "react"
import { Edit, FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin"

const addressTitle = choice => (
  <span>
    {choice.address} {choice.postnumber} {choice.city}{" "}
  </span>
)

const SubscriptionEdit = (props) => {

  return (    
    <Edit undoable={false}  {...props} >
      <SimpleForm {...props} redirect="edit">
        <PageFieldset>
          <ReferenceInput source="user_id" reference="user" resettable>
            <SelectInput optionText="email" />
          </ReferenceInput>
          <TextInput source="reference" />

          <FormDataConsumer>
            {({record}) => { 
              return record.account_id &&
              <>
                <ReferenceInput source="delivery_address_id" reference="address" resettable filter={{account_id: record.account_id}}>
                  <SelectInput optionText={addressTitle} />
                </ReferenceInput>
                <ReferenceInput source="invoice_address_id" reference="address" resettable filter={{account_id: record.account_id}}>
                  <SelectInput optionText={addressTitle} />
                </ReferenceInput>
              </>
              }
          }
          </FormDataConsumer>
        </PageFieldset>
      </SimpleForm>
    </Edit>    
  )
}

export default SubscriptionEdit
