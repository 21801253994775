// in Offers.js
import React from "react"
import { Create, TextInput, NumberInput, ReferenceInput, SelectInput, DateInput } from "react-admin"
import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const OfferCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <TextInput source="name" />
        <TextInput source="code" />
        <NumberInput source="amount" />
        <ReferenceInput source="vat_id" reference="vat" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="product_id" reference="product" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="period_unit_id" reference="period_unit" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </PageFieldset>
      <PageFieldset>
        <DateInput source="start_at" />
        <DateInput source="end_at" />
        <TextInput source="title" />
        <TextInput source="description" />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default OfferCreate
