
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import EmailMessageShow from "./EmailMessageShow.js"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>  
    <PageBreadcrumb {...props} />
    <EmailMessageShow {...sanitizeEditProps(props)} />
  </>
)
