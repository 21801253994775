// in AccountInviteButton.js
import IconContentAdd from "@material-ui/icons/Add"
import React from "react"
import {
  Button, Create, SimpleForm,
  TextInput,
  useRefresh
} from "react-admin"
import RpcFormModal, { cleanProps, useRpcFormModal } from "RpcFormModal"

const AccountInviteButton = (props) => {

  const refresh = useRefresh();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record, resource } = props
  if (!record.id) return null

  const resource_path = `${resource}/${record.id}/invite`
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    setModalOpen(false)
    refresh()
  }

  return (
    <>
      <Button onClick={toggle} label="ra.action.invite">
        <IconContentAdd />
      </Button>
      <RpcFormModal modalOpen={modalOpen} toggle={toggle}>
        <Create {...rest} resource={resource_path} record={null}>
          <SimpleForm redirect={redirect}>
            <TextInput source="email" />
            <TextInput source="name" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default AccountInviteButton
