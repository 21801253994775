import React from "react"
import { Create, TextInput, ReferenceInput, AutocompleteInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const AddressCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <ReferenceInput label="Konto" source="account_id" reference="account" filterToQuery={searchText => ({ domain: searchText })} allowEmpty>
          <AutocompleteInput optionText="domain" allowEmpty />
        </ReferenceInput>

        <TextInput source="name" />
        <TextInput source="co" />
        <TextInput source="address" />
        <TextInput source="postnumber" />
        <TextInput source="city" />
        <TextInput source="email" />
        <TextInput source="phone" />
        <TextInput source="reference" />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default AddressCreate
