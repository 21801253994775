import React from "react"
import { SimpleForm, BooleanInput, TextInput, ReferenceInput, SelectInput, NumberInput, Edit, FormDataConsumer, ReferenceField } from "react-admin"
import { useTranslate } from 'react-admin'

import PageFieldset from "components/PageFieldset"

import AmountTypeInput from "components/AmountTypeInput"
import PriceField from "components/PriceField"

const Title = ({ record }) => {
  return <span>Service {record ? `"${record.name}"` : ""}</span>
}

const ServiceEdit = ({ ...props }) => {
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} title={<Title />}>
      <SimpleForm {...props} redirect="edit">
        <PageFieldset>
          <ReferenceInput source="offer_id" reference="offer" allowEmpty>
            <SelectInput optionText="name" />
          </ReferenceInput>

          <BooleanInput source="auto_assign" />
        </PageFieldset>
        <PageFieldset>
          <TextInput source="reference" />
          <AmountTypeInput source="amount_type"/>
          <NumberInput helperText={translate("resources.service.fields.amount_use_default")} source="amount" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData &&
              formData.product_id && (
                <ReferenceField link={false} source="offer_id" reference="offer" allowEmpty filter={{ product_id: formData.product_id }} {...rest}>
                  <PriceField source="amount" />
                </ReferenceField>
              )
            }
          </FormDataConsumer>
        </PageFieldset>
      </SimpleForm>
    </Edit>
  )
}

export default ServiceEdit
