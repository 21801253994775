// in Users.js
import React from "react"

import { CardActions, CreateButton, ExportButton } from "react-admin"

import ShowAccountButton from "components/ShowAccountButton"

export const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  children
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    {children &&
      React.cloneElement(children, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} />
  </CardActions>
)

export const EditActions = ({ basePath, resource, data, children, label }) => (
  <CardActions style={{ justifyContent: "flex-start" }}>
    {data && data.account_id && (
      <ShowAccountButton
        label="Konto"
        to={{
          pathname: `/account/${data.account_id}`
        }}
      />
    )}
    {data && data.product_id && (
      <ShowAccountButton
        label="Produkt"
        to={{
          pathname: `/product/${data.product_id}`
        }}
      />
    )}
    <div style={{ flex: 1 }} />
    {children && React.Children.map(children, child => React.cloneElement(child, { basePath, data }))}
  </CardActions>
)
