// in Products.js
import React from "react"
import { Edit, SimpleForm, ReferenceInput, NumberInput, SelectInput, TextInput, BooleanInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => `${record.name}`

const ProductEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <TextInput source="name" />
        <NumberInput source="position" />
        <TextInput source="product_number" />
        <TextInput source="url" />
        <TextInput source="code" />
        <ReferenceInput label="resources.client.label" source="client_id" reference="client" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="hidden" />
        <TextInput source="logo" />
        <TextInput source="icon" />
      </PageFieldset>

      <PageFieldset>
        <TextInput source="title" />
        <TextInput multiline source="description" />
        <TextInput multiline rows={10} source="feature" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default ProductEdit

