
import React from "react"
import { ReferenceManyField, Datagrid, TextField, ReferenceField, Show, TabbedShowLayout, Tab} from "react-admin"
import DateTimeField from "components/DateTimeField"

const ServiceTabs = ({ ...props }) => {
  return (
    <Show title={' '} {...props} >
      <TabbedShowLayout redirect="edit" >
        <Tab label="resources.account.tabs.subscriptions">
          <ReferenceManyField reference="subscription" target="service_id" label="Abonnememnte" addLabel={false}>
            <Datagrid rowClick="edit">
              <TextField source="reference" />

              <ReferenceField label="Bruker" source="user_id" reference="user" allowEmpty>
                <TextField source="email" />
              </ReferenceField>

              <DateTimeField label="Opprettet" source="created" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
}


export default ServiceTabs
