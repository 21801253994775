import React, { Fragment } from "react"
import AccountInfoField from "components/AccountInfoField"
import DateTimeField from "components/DateTimeField"
import PriceField from "components/PriceField"
import ProductChipField from "components/ProductChipField"
import RoleField from "components/RoleField"
import ProductInfoField from "components/ProductInfoField"
import ReferenceManyFieldList from "components/ReferenceManyFieldList"
import { BooleanField, BulkDeleteButton, Datagrid, ReferenceField, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField } from "react-admin"
import AccountActivationButton from "resources/account_activation_queue/AccountActivationButton"
import AccountBulkCancelSubscriptionButton from './AccountBulkCancelSubscriptionButton'
import AccountBulkMoveUserButton from './AccountBulkMoveUserButton'
import AccountBulkSubscriptionButton from './AccountBulkSubscriptionButton'
import AccountInviteButton from './AccountInviteButton'
import AccountServiceButton from './AccountServiceButton'
import AccountUserImportButton from './AccountUserImportButton'

const AccountServiceBulkActions = props => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const AccountSubscriptionBulkActions = props => (
  <Fragment>
    <AccountBulkCancelSubscriptionButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const AccountUserBulkActions = props => (
  <Fragment>
    <AccountBulkSubscriptionButton {...props} />
    <AccountBulkMoveUserButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);


const AccountTabs = ({ ...props }) => {
  return (
    <Show title={' - '} {...props} >
      <TabbedShowLayout redirect="edit" >
          <Tab label="resources.account.tabs.service">
            <ReferenceManyFieldList reference="service_summary" target="account_id"  actions={<AccountServiceButton />} bulkActionButtons={<AccountServiceBulkActions/>}>
              <Datagrid rowClick={id => `/service/${id}`}>
                <TextField source="reference" />
                <TextField source="product_name" />
                <TextField source="offer_name" />
                <PriceField source="amount" />
                <PriceField source="amount_calculated" />
                <TextField source="usage_count" />
                <BooleanField source="auto_assign" />
              </Datagrid>
            </ReferenceManyFieldList>
          </Tab>
          <Tab label="resources.account.tabs.subscriptions">
            <ReferenceManyFieldList reference="subscription_summary" target="account_id"  actions={<AccountUserImportButton />} bulkActionButtons={<AccountSubscriptionBulkActions/>}>

              <Datagrid rowClick={id => `/subscription/${id}`}>
                <TextField source="product_name" />
                <TextField source="offer_name" />
                <TextField source="reference" />
                <TextField source="address" />
                <TextField source="email" />
                <DateTimeField label="Opprettet" source="created" />
                <DateTimeField label="Neste periode" source="datestamp" />
              </Datagrid>
            </ReferenceManyFieldList>
          </Tab>
          <Tab label="resources.account.tabs.users">
            <ReferenceManyFieldList reference="account_user_product_tab" target="account_id"  sort={{ field: 'email', order: 'ASC' }} actions={[<AccountUserImportButton  key="1"/>,<AccountInviteButton key="2"/>]} bulkActionButtons={<AccountUserBulkActions/>}>
              <Datagrid rowClick={id => `/user/${id}`}>
                <TextField source="email" />
                <RoleField source="role_id" />
                <ProductChipField source="products" />
                <DateTimeField label="Opprettet" source="created" />
                <DateTimeField showTime label="Siste innlogging" source="last_login" />
              </Datagrid>
            </ReferenceManyFieldList>

          </Tab>
          <Tab label="resources.account.tabs.address">
            <ReferenceManyField reference="address" target="account_id" >
              <Datagrid rowClick="edit">
                <TextField source="email" />
                <TextField source="name" />
                <TextField source="address" />
                <TextField source="postnumber" />
                <TextField source="city" />
                <TextField source="email" />
                <DateTimeField source="created" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="resources.account.tabs.invoice_pending">
            <ReferenceManyField reference="invoice_item_pending" target="account_id" >
              <Datagrid>
                <AccountInfoField source="orgnr" label="Firma"/>
                <ProductInfoField source="offer_name" label="Produkt"/>
                <DateTimeField source="datestamp" label="Start datum"/>
                <TextField source="reference" />
                <TextField source="email" label="Bruker"/>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="resources.account.tabs.invoice">
            <ReferenceManyField reference="invoice" target="account_id" >
              <Datagrid rowClick="show">
                <TextField source="orgnr" />
                <TextField source="reference" />

                <DateTimeField source="created" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="resources.account.tabs.account_activation_queue_report">
            <ReferenceManyField reference="account_activation_queue_report" target="account_id" >
              <Datagrid>
                <TextField source="user_email" />
                <TextField source="user_name" />
                <TextField source="subject_entity" />
                <TextField source="title" />
                <TextField source="subtitle" />
                <DateTimeField source="created" />
                <AccountActivationButton approve={true}  />
                <AccountActivationButton approve={false}  />
              </Datagrid>
            </ReferenceManyField>
          </Tab>

      </TabbedShowLayout>
    </Show>
  )
}

export default AccountTabs
