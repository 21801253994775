// in AccountBulkMoveUserButton.js
import IconContentAdd from "@material-ui/icons/Add"
import { priceFormater } from 'components/PriceField'
import { accountFormatName } from "components/utils"
import React from "react"
import {
  AutocompleteInput, Button, Create,
  FormDataConsumer, ReferenceInput,
  SelectInput, SimpleForm,
  TextInput,
  useRefresh,
  useUnselectAll
} from "react-admin"
import RpcFormModal, { cleanProps, useRpcFormModal } from "RpcFormModal"

const optionRenderer = choice => <span>{choice.offer_name} - {priceFormater(choice.amount_calculated)}</span>

const AccountBulkMoveUserButton = (props) => {

  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record = {id: 1}, resource, selectedIds } = props

  if (!record || !record.id) return null

  const resource_path = `account/${record.id}/move_user`
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    unselectAll(resource)
    setModalOpen(false)
    refresh()
  }

  return (
    <>
      <Button onClick={toggle} label="ra.action.move_user">
        <IconContentAdd />
      </Button>
      <RpcFormModal modalOpen={modalOpen} toggle={toggle} label={`Flytte ${selectedIds.length} bruker.`}>
        <Create {...rest} resource={resource_path} record={null} >
          <SimpleForm redirect={redirect} initialValues={ {selectedIds } }>
            <ReferenceInput source="account_id" reference="account" filterToQuery={q => ({ company: q })} allowEmpty>
              <AutocompleteInput optionText={accountFormatName} />
            </ReferenceInput>
            <FormDataConsumer>
                 {({ formData, ...rest }) => formData.account_id &&
                  <ReferenceInput source="service_id" reference="service_summary" allowEmpty filter={{account_id: formData.account_id}}>
                    <SelectInput optionText={optionRenderer}/>
                  </ReferenceInput>
                 }
             </FormDataConsumer>
            <TextInput source="reference" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default AccountBulkMoveUserButton
