// in Users.js
import React from "react"

import { List, Pagination } from "react-admin"

export const ListPagination = props => <Pagination rowsPerPageOptions={[50, 100, 1000]} {...props} />;

export const standardPagination = {
  pagination: (<ListPagination />), 
  perPage: 100
} 
export default props => <List {...props} {...standardPagination} />;

