import React from "react"
import { Button, useUnselectAll, useUpdateMany, useRefresh, useNotify } from "react-admin"

import IconContentAddCircle from "@material-ui/icons/AddCircle"
import IconContentDelete from "@material-ui/icons/Delete"

const AccountActivationButton = ({ selectedIds, approve, record, resource }) => {

  const ids = selectedIds ? selectedIds : record ? [record.id] : []
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll()
  const label = approve ? 'resources.account_activation_queue_report.actions.approve' : 'resources.account_activation_queue_report.actions.reject'
  const icon = approve ? <IconContentAddCircle /> : <IconContentDelete/>
  const [updateMany, { loading }] = useUpdateMany(
      'account_activation_queue_report',
      ids,
      { approve },
      {
          onSuccess: () => {
              refresh();
              notify(label);
              unselectAll(resource);
          },
          onFailure: () => notify('Error: posts not updated', 'warning'),
      }
  );

  return (
    <Button
      label={label}
      disabled={loading}
      onClick={updateMany}
    >
      {icon}
    </Button>
  );
};

export default AccountActivationButton
