
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import PeriodUnitEdit from "./PeriodUnitEdit"


const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <PeriodUnitEdit {...sanitizeEditProps(props)} />
  </>
)
