
import React from "react"
import { DateInput, ReferenceInput, AutocompleteInput, Edit, TextInput, SimpleForm } from "react-admin"

import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => `${record.email || ''}`

const UserAuthEdit = ({...props}) => {
  return (
    <Edit undoable={false} {...props} title={<Title />} >
      <SimpleForm {...props} redirect="edit" >
        <PageFieldset>
          <TextInput source="email" />
          <DateInput source="last_login" />
          <ReferenceInput source="user_id" reference="user" filterToQuery={searchText => ({ email: searchText })} allowEmpty>
            <AutocompleteInput optionText="email" />
          </ReferenceInput>
        </PageFieldset>
      </SimpleForm>
    </Edit>
  )
}

export default UserAuthEdit
