// in UserAuthSetPasswordButton.js
import React from "react"
import {
  SimpleForm,
  TextInput,
  Create,
  Button,
  useRefresh
} from "react-admin"
import IconContentAdd from "@material-ui/icons/Add"
import RpcFormModal, {useRpcFormModal, cleanProps} from "RpcFormModal"

const UserAuthSetPasswordButton = (props) => {

  const refresh = useRefresh();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record, resource } = props

  if (!record || !record.id) return null
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    setModalOpen(false)
    refresh()
  }

  return (
    <>
      <Button onClick={toggle} label="ra.action.setpassword">
        <IconContentAdd />
      </Button>
     <RpcFormModal modalOpen={modalOpen} toggle={toggle}>
      <Create {...rest} resource={`${resource}/${record.id}/set_password`} record={null}>
          <SimpleForm redirect={redirect}>
            <TextInput source="password" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default UserAuthSetPasswordButton
