
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import UserAuthEdit from "./UserAuthEdit"
import UserAuthTabs from "./UserAuthTabs"

const sanitizeShowProps = ({hasEdit, hasList, hasShow, hasCreate, ...rest}) => rest
const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <UserAuthEdit {...sanitizeEditProps(props)} />
    <UserAuthTabs {...sanitizeShowProps(props)} hasEdit={false} />
  </>
)
