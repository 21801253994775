import DateTimeField from "components/DateTimeField"
import JsonField from "components/JsonField"
import React from "react"
import { Datagrid, Filter, List, TextInput } from "react-admin"

const AccountExtendedFilter = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="ra.filters.email_status.message" source="out_message" parse={v => (v ? `%${v}` : "")} format={v => (v ? v.replace("%", "") : "")} />
  </Filter>
)
const EmailStatusList = props => (
  <List {...props} filters={<AccountExtendedFilter />} sort={{ field: 'created', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <JsonField source="out_message" property="to" />
      <JsonField source="out_message" property="subject" />
      <DateTimeField showTime label="Opprettet" source="created" />
    </Datagrid>
  </List>
)

export default EmailStatusList
