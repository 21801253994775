// in EmailTemplates.js
import React from "react"
import { Create, TextInput, SelectInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const EmailTemplateCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <SelectInput
          source="event"
          choices={[
            { id: "BASIS", name: "BASIS" },
            { id: "PLUS", name: "PLUS" },
            { id: "PASSWORD_RESET", name: "PASSWORD_RESET" },
            
            { id: "USER_TOKEN", name: "USER_TOKEN" },
            { id: "USER_INVITE", name: "USER_INVITE" },
            { id: "ACCOUNT_ADMIN_PENDING", name: "ACCOUNT_ADMIN_PENDING" },
            { id: "ABONNEMENT_MAIL_NOTIFICATION", name: "ABONNEMENT_MAIL_NOTIFICATION" },

            { id: "USER_CREATE", name: "USER_CREATE" },
            { id: "USER_CREATE_LDB", name: "USER_CREATE_LDB" },
            { id: "USER_CREATE_KB", name: "USER_CREATE_KB" },
            { id: "USER_ACTIVATION", name: "USER_ACTIVATION" },
            { id: "USER_REGISTRATION", name: "USER_REGISTRATION" },

            { id: "ACCOUNT_CREATE_KB", name: "ACCOUNT_CREATE_KB" },
            { id: "ACCOUNT_CREATE_LDB", name: "ACCOUNT_CREATE_LDB" },
            { id: "ACCOUNT_CREATE_KRD", name: "ACCOUNT_CREATE_KRD" },
          ]}
          allowEmpty
        />
        <TextInput source="subject" />
      </PageFieldset>
      <PageFieldset>
        <TextInput source="to" type="email" />
        <TextInput source="bcc" type="email" />
      </PageFieldset>
      <PageFieldset wide>
        <TextInput component="pre" source="body" />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default EmailTemplateCreate
