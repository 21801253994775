import React from "react"
import { Show, SimpleShowLayout, ReferenceField, FunctionField } from "react-admin"
import DateTimeField from "components/DateTimeField"
import JsonField from "components/JsonField"

const EmailStatusTitle = ({ record }) => (record ? `"${record.subject}"` : "")

const EmailStatusShow = props => (
  <Show title={<EmailStatusTitle />} {...props}>
    <SimpleShowLayout>
      <DateTimeField source="created" />
      <JsonField source="out_message" property="from" label="From" />
      <JsonField source="out_message" property="to" label="To" />
      <JsonField source="out_message" property="subject" label="Subject" />
      <JsonField source="out_message" property="text" label="Text Mail" />
      <JsonField source="out_message" property="html" label="HTML Mail" />
      <ReferenceField source="email_message_id" reference="email_message" allowEmpty link={false} label="Dynamisk data">
        <FunctionField render={record => `${JSON.stringify(record.params) || ""} `} />
      </ReferenceField>
      <JsonField source="error" />
    </SimpleShowLayout>
  </Show>
)

export default EmailStatusShow
