import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SearchDialogButton from "components/SearchDialogButton"
import { LoadingIndicator } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';
import LogoutButton from "./LogoutButton"

const useStyles = makeStyles({
  title: {
      flex: 1
  }
});


const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
        <Toolbar>
          <Typography variant="h6" id="react-admin-title" className={classes.title}/>
          <SearchDialogButton />
          <LoadingIndicator />
          <LogoutButton />
        </Toolbar>
    </AppBar>
)
  }

export default MyAppBar;



