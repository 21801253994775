import React from "react"
import { Datagrid, Show, SimpleShowLayout, TextField, ReferenceManyField, SingleFieldList } from "react-admin"
import { EditActions } from "components/ResourceActions"
import RevisionInfoAside from "components/RevisionInfoAside"
import PriceField from "components/PriceField"
import InvoiceExternalOrderField from "./InvoiceExternalOrderField"

const InvoiceTitle = ({ record }) => {
  return <span>Invoice {record ? `"${record.name}"` : ""}</span>
}

const InvoiceShow = props => (
  <Show title={<InvoiceTitle />} actions={<EditActions />} aside={<RevisionInfoAside />} {...props}>
    <SimpleShowLayout>
      <TextField source="orgnr" />
      <TextField source="reference" />
      <ReferenceManyField label="Tripletex" reference="invoice_extended" target="invoice_id">
        <SingleFieldList>
          <InvoiceExternalOrderField source="external_order_id" />
        </SingleFieldList>
      </ReferenceManyField>

      <ReferenceManyField reference="invoice_item" target="invoice_id" addLabel={false}>
        <Datagrid rowClick="edit">
          <TextField source="unit_title" />
          <TextField source="unit_subtitle" />
          <TextField source="user_email" />
          <TextField source="unit_vat_name" />
          <PriceField source="unit_amount" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default InvoiceShow
