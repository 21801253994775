import React from "react"
import { Show, SimpleShowLayout, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"

const AccountExtendedShow = ({ record }) => {
  return <span>AuditLog {record ? `"${record.name}"` : ""}</span>
}

const AuditLogShow = props => (
  <Show title={<AccountExtendedShow />} {...props}>
    <SimpleShowLayout>
      <TextField source="account_id" />
      <TextField source="tripletex_id" />
      <TextField source="tripletex_customer_number" />
      <TextField source="status" />
      <TextField source="info" />
      <DateTimeField source="created" />
    </SimpleShowLayout>
  </Show>
)

export default AuditLogShow
