import { fetchUtils } from 'react-admin';
import * as tokenlib from "./token"
import { stringify } from "query-string"
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE, UPDATE_MANY } from "ra-core"

const select_params = resource => {
  const params = computed_params(resource)
  return ["*", ...params].join(",")
}

const remove_computed_params = (resource, obj) => {
  const params = computed_params(resource)
  const newObj = { ...obj }
  params.forEach(p => {
    delete newObj[p]
  })
  return newObj
}

const computed_params = resource => {
  switch (resource) {
    case "user":
      return ["last_login"]
    default:
      return []
  }
}

export const convertFilters = filters => {
  const rest = {}
  Object.keys(filters).map(key => {
    switch (typeof filters[key]) {
      case "string":
        rest[key] = `ilike.*${filters[key]}*`
        break

      case "boolean":
        if (key.indexOf('_id') ) {
          if (filters[key] === false) {
            rest[key] = `is.null`
          } else {
            rest[key] = `is.notnull`
          }
        } else {
          rest[key] = `is.${filters[key]}`
        }
        break

      case "undefined":
        rest[key] = "is.null"
        break

      case "number":
        rest[key] = `eq.${filters[key]}`
        break

      default:
        rest[key] = `eq.${filters[key]}`
        break
    }
    return true
  })
  return rest
}

/**
 * Maps react-admin queries to a postgrest API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_MANY_REFERENCE
 *              => GET http://my.api.url/posts/2
 * GET_LIST     => GET http://my.api.url/posts?order=title.asc
 * GET_ONE      => GET http://my.api.url/posts?id=eq.123
 * GET_MANY     => GET http://my.api.url/posts?id=in.123,456,789
 * UPDATE       => PATCH http://my.api.url/posts?id=eq.123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts?id=eq.123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

  const singleResourceUrl = (resource, params) => {
    return `${apiUrl}/${resource}/${params.id}`
  }

  const setSingleResponseHeaders = options => {
    options.headers.set("Prefer", "return=representation")
    options.headers.set("Accept", "application/vnd.pgrst.object+json")
  }

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = ""
    const options = {}
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" })
    }
    const token = tokenlib.get()
    if (token && token.length > 0) {
      options.headers.set("Authorization", `Bearer ${token}`)
    }

    const record = remove_computed_params(resource, params.data)
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        if (perPage < 1000) {
          options.headers.set("Range-Unit", "items")
          options.headers.set("Range", `${(page - 1) * perPage}-${page * perPage - 1}`)
          options.headers.set("Prefer", "count=exact")
        }
        const query = {
          order: `${field}.${order.toLowerCase()}.nullslast`
        }
        Object.assign(query, convertFilters(params.filter))
        url = `${apiUrl}/${resource}?${stringify(query)}`
        url += "&select=" + select_params(resource)
        break
      }
      case GET_ONE:
        url = singleResourceUrl(resource, params)
        setSingleResponseHeaders(options)
        break
      case GET_MANY:
        //	let query_id = `${resource}_id`;
        url = `${apiUrl}/${resource}?id=in.(${params.ids.join(",")})`
        break
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        if (perPage < 1000) {
          options.headers.set("Range-Unit", "items")
          options.headers.set("Range", `${(page - 1) * perPage}-${page * perPage - 1}`)
          options.headers.set("Prefer", "count=exact")
        }

        const filters = {} //params.filter || {};
        const { field, order } = params.sort
        filters[params.target] = params.id
        const query = {
          order: `${field}.${order.toLowerCase()}.nullslast`
        }
        Object.assign(query, convertFilters(filters), convertFilters(params.filter))
        url = `${apiUrl}/${resource}?${stringify(query)}`
        url += "&select=" + select_params(resource)
        break
      }
      case UPDATE:
        url = singleResourceUrl(resource, params)
        setSingleResponseHeaders(options)
        var updateRecord = { ...record }
        Object.keys(record).forEach(key => {
          if (key.match(/_id$/) && record[key] === "") {
            updateRecord[key] = null
          }
        })
        options.method = "PATCH"
        options.body = JSON.stringify(updateRecord)
        break
      case CREATE:
        url = `${apiUrl}/${resource}`
        setSingleResponseHeaders(options)
        options.method = "POST"
        options.body = JSON.stringify(record)
        break
      case DELETE:
        url = params.ids ? `${apiUrl}/${resource}?id=in.(${params.ids.join(",")})` : singleResourceUrl(resource, params)
        //url = params.ids ? `${apiUrl}/${resource}?id=in.(${params.ids.join(",")})` : singleResourceUrl(resource, params);
        options.method = "DELETE"
        break
      case DELETE_MANY:
          url = `${apiUrl}/${resource}?id=in.(${params.ids.join(",")})`
          options.method = "DELETE"
          break
      case UPDATE_MANY:
          url = `${apiUrl}/${resource}?id=in.(${params.ids.join(",")})`
          var updateMenyRecord = { ...record }
          Object.keys(record).forEach(key => {
            if (key.match(/_id$/) && record[key] === "") {
              updateMenyRecord[key] = null
            }
          })
          options.method = "PATCH"
          options.body = JSON.stringify(updateMenyRecord)
          break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { headers, json } = response

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE: {
        if (!headers.has("content-range")) {
          throw new Error(
            "The Content-Range header is missing in the HTTP Response. " +
              "The PostgREST client expects responses for lists of resources to contain " +
              "this header with the total number of results to build the pagination. " +
              "If you are using CORS, did you declare Content-Range in the " +
              "Access-Control-Expose-Headers header?"
          )
        }
        const rangeParts = headers.get("content-range").split("/")
        const total = parseInt(rangeParts.pop(), 10) || parseInt(rangeParts[0].split("-").pop(), 10) + 1 || 0
        return {
          data: json.slice(),
          total
        }
      }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      case DELETE_MANY:
        return { data: [] }
      case DELETE:
        return { data: { id: params.id } }
      case UPDATE_MANY:
        return { data: [] }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params)
    return httpClient(url, options).then(response => convertHTTPResponseToREST(response, type, resource, params))
  }
}
