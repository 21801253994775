import React from "react"
import { BooleanField, List, Datagrid, TextField, NumberField, ReferenceField } from "react-admin"
import DateTimeField from "components/DateTimeField"
const ServiceList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="reference" />
      <ReferenceField source="offer_id" reference="offer">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Konto" source="account_id" reference="account" allowEmpty>
        <TextField source="domain" />
      </ReferenceField>
      <NumberField source="amount" />
      <TextField source="usage_count" />
      <BooleanField source="auto_assign" />
      <DateTimeField source="created" />
      <DateTimeField source="updated" />
    </Datagrid>
  </List>
)

export default ServiceList
