// in UserAuthSendPasswordTokenButton.js
import React from "react"
import {
  SimpleForm,
  TextInput,
  Create,
  Button,
  ReferenceInput,
  SelectInput,
  NumberInput,
  useRefresh

} from "react-admin"

import IconContentAdd from "@material-ui/icons/Add"
import RpcFormModal, {useRpcFormModal, cleanProps} from "RpcFormModal"
import AmountTypeInput from "components/AmountTypeInput"

const UserAuthSendPasswordTokenButton = ({key, ...props}) => {


  const refresh = useRefresh();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record, resource } = props

  if (!record.id) return null
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    setModalOpen(false)
    refresh()
  }

  return (
    <>
      <Button onClick={toggle} label="resources.account.actions.service">
        <IconContentAdd />
      </Button>
      <RpcFormModal modalOpen={modalOpen} toggle={toggle}>
      <Create {...rest} resource={`${resource}/${record.id}/service`} record={null}>
          <SimpleForm redirect={redirect}>
          <ReferenceInput source="offer_id" reference="offer" allowEmpty>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <AmountTypeInput source="amount_type"/>
          <NumberInput source="amount" />

          <TextInput source="reference" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default UserAuthSendPasswordTokenButton
