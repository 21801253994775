// in roles.js
import React from "react"
import { SimpleForm, Edit, TextInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const RoleEdit = props => (
  <Edit undoable={false} {...props} >
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <TextInput source="name" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default RoleEdit
