import React from "react"
import { Edit, SimpleForm, TextField, TextInput } from "react-admin"
import JsonField from "components/JsonField"

import DateTimeField from "components/DateTimeField"
const SubscriptionLogTitle = ({ record }) => {
  return <span>SubscriptionLog {record ? `"${record.name}"` : ""}</span>
}

const SubscriptionLogEdit = props => (
  <Edit undoable={false} title={<SubscriptionLogTitle />} {...props}>
    <SimpleForm>
      <TextField source="operation" />
      <DateTimeField source="created" />
      <TextInput rows={10} multiline source="comments" />
      <JsonField source="payload" property="user" label="Bruker" />
      <JsonField source="payload" property="account" label="Konto" />
      <JsonField source="payload" property="subscription" label="Produkt" />
      <JsonField source="payload" property="delivery_address" label="Adresse" />
      <JsonField source="payload" property="invoice_address" label="Faktura adresse" />
    </SimpleForm>
  </Edit>
)

export default SubscriptionLogEdit
