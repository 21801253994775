import React from "react"
import './JsonField.css'
const JsonFieldValue = ({ value }) => {
  if (typeof value === "object" )
  {
    return (
      <div className="JsonField">
          {
            Object.keys(value).map(key => <div className="JsonFieldValue"><span>{key}</span><span>{value[key]}</span></div>)
          }
      </div>
    )
  }
  return <pre>{value}</pre>
}

const JsonField = ({ record, source, property = false, label = "" }) => {
  if (!record || !source || !record[source]) return null
  let json = JSON.parse(record[source])

  if (!json) return null

  let text = property ? json[property] : JSON.stringify(json, null, 4)
  return <>
    {label && <h5>{label}</h5> }
    <JsonFieldValue value={text} />
  </>
}

export default JsonField
