import React from "react"
import { ReferenceField, List, Datagrid, TextField, ShowButton } from "react-admin"
import DateTimeField from "components/DateTimeField"
const SubscriptionLogList = props => (
  <List {...props} sort={{ field: 'created', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="operation" />
      <ReferenceField source="account_id" reference="account" allowEmpty link={false}>
        <TextField source="company" />
      </ReferenceField>
      <ReferenceField source="user_id" reference="user" allowEmpty link={false}>
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="product_id" reference="product" allowEmpty link={false}>
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="offer_id" reference="offer" allowEmpty link={false}>
        <TextField source="title" />
      </ReferenceField>
      <TextField source="comment" />
      <DateTimeField source="created" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default SubscriptionLogList
