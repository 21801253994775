
import React from "react"
import { BooleanInput, ReferenceInput, SelectInput, AutocompleteInput, Edit, TextInput, SimpleForm } from "react-admin"

import PageFieldset from "components/PageFieldset"
import { accountFormatName } from "components/utils"

const Title = ({ record }) => `${record.name || record.email || ''}`

const UserEdit = ({...props}) => {
  return (
    <Edit undoable={false} {...props} title={<Title />}>
      <SimpleForm {...props} redirect="edit">
        <PageFieldset>
          <TextInput disabled source="id" />
          <TextInput source="email" label="Epost" />
          <TextInput source="name" />
          <TextInput source="activation" label="Aktiveringskode" />
        </PageFieldset>
        <PageFieldset>
          <BooleanInput source="archived" />

          <ReferenceInput label="resources.role.label" source="role_id" reference="role" allowEmpty>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="account_id" reference="account" filterToQuery={searchText => ({ company: searchText })} resettable allowEmpty>
            <AutocompleteInput optionText={accountFormatName} />
          </ReferenceInput>
        </PageFieldset>
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit
