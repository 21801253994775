import React from "react"
import { Show, TabbedShowLayout, Tab, Datagrid, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"
import UserAuthSendPasswordTokenButton from "./UserAuthSendPasswordTokenButton"
import UserAuthSetPasswordButton from "./UserAuthSetPasswordButton"

import ReferenceManyFieldList from "components/ReferenceManyFieldList"

const TokenUrlField = ({ record = {} }) => (
  <span>
    <pre>
      https:{process.env.REACT_APP_SSO_URL}/{record.client_uuid}/token/{record.token}
    </pre>
  </span>
)

const UserTabs = ({ ...props }) => {
  return (
    <Show title={' '} {...props} >
      <TabbedShowLayout redirect="edit" >
        <Tab label="resources.user_token.label">
          <ReferenceManyFieldList reference="user_token" target="user_auth_id" label="Token" actions={[<UserAuthSendPasswordTokenButton />, <UserAuthSetPasswordButton/>]}>
            <Datagrid>
              <TokenUrlField source="token" />
              <DateTimeField source="expire" />
              <DateTimeField source="claimed" />
              <TextField source="event" />
              <DateTimeField source="created" />
            </Datagrid>
          </ReferenceManyFieldList>
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
}


export default UserTabs

