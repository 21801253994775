import React, { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useNotify,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchEnd } from 'react-admin';
import IconPostAdd from "@material-ui/icons/PostAdd"
import { RpcHeader } from "components/utils"

import { convertFilters } from "postgrestClient"

const InvoiceAllButton = ({ resource, sort, filter, ...rest }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const createInvoice = () => {
        
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator 
        const filters = convertFilters(filter)
        const request = new Request(process.env.REACT_APP_API_URL + "/rest/invoice_item_pending_invoice", {
            method: 'POST',
            body: JSON.stringify({ sort, filters }),
            headers: RpcHeader()
          })

        fetch(request)
        .then(() => {
            notify('Comment approved');
        })
        .catch((e) => {
            notify('Error: comment not approved', 'warning')
        })
        .finally(() => {
            setLoading(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        }); 
    }

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        createInvoice();
        setOpen(false);
    };

    return (
        <Fragment>

            <Button label="Fakturera alle" onClick={handleClick}><IconPostAdd /></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title="Update View Count"
                content="Are you sure you want to reset the views for these items?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default InvoiceAllButton;
