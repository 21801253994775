// in Vats.js
import React from "react"
import { List, Datagrid, TextField } from "react-admin"

const VatList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="value" />
      <TextField source="number" />
    </Datagrid>
  </List>
)

export default VatList
