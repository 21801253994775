import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import * as token from "token"

class SsoCallback extends Component {
  componentDidMount() {
    const jwt = window.location.hash.split("/").pop()
    token.set(jwt)
    if (token.isAdmin()) {
      this.props.history.push("/")
    }
  }

  handleLogout = () => {
    this.props.history.push("/logout")
  }

  render() {
    if (!token.isAdmin()) {
      return <>
        <p>Access denied</p>
        <button onClick={this.handleLogout}>Logout</button>
      </>
    }
    return null
  }
}

export default withRouter(SsoCallback)
