import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "react-admin"
import * as token from "token"

export default (type, params) => {
  if (type === AUTH_LOGOUT) {
    token.remove()
  }

  if (type === AUTH_LOGIN) {
    // Noop
  }
  if (type === AUTH_CHECK) {
    return token.isAdmin() ? Promise.resolve() : Promise.reject()
  }
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      token.remove()
      return Promise.reject()
    }
    return Promise.resolve()
  }
  return Promise.resolve()
}
