
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import RoleEdit from "./RoleEdit"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <RoleEdit {...sanitizeEditProps(props)} />
  </>
)
