import React from "react"
import Chip from '@material-ui/core/Chip';

export default ({ record = {} }) => (
  <span>
    { record['next_canceled'] && 
      <Chip label="Avslutad" />
    }
    { record['canceled'] && 
      <Chip label="Canceled" />
    }
    { record['billed'] && 
      <Chip label="Fakturerad" />
    }
  </span>
);
