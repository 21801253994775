// in ./AccountExtendedRefreshButton.js
import React, { Fragment, Component } from "react"
import { connect } from "react-redux"
import { refreshView as refreshViewAction } from "ra-core"
import { RpcHeader } from "components/utils"

import { Button, Confirm, fetchEnd, fetchStart, showNotification, translate } from "react-admin"

class AccountExtendedRefreshButton extends Component {
  state = {
    isOpen: false
  }

  handleClick = () => {
    this.setState({ isOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false })
  }
  handleCreateInvoice = () => {
    const { refreshView, showNotification, selectedIds } = this.props

    const data = Object.assign({}, selectedIds)
    const body = JSON.stringify({ ids: data })
    const request = new Request(process.env.REACT_APP_API_URL + "/rpc/account_extended_refresh", {
      method: "POST",
      body,
      headers: RpcHeader({ Accept: "application/vnd.pgrst.object+json" })
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        this.setState({ isOpen: false })
        refreshView()
        showNotification("Invoice sent")
      })
      .catch(error => {
        showNotification(error.message, "error")
      })
      .finally(() => {
        //fetchEnd();
      })
  }
  render() {
    const { translate } = this.props
    return (
      <Fragment>
        <Button label={translate("ra.action.tripletex_sync")} onClick={this.handleClick} />
        <Confirm
          isOpen={this.state.isOpen}
          title={translate("ra.modal.tripletex_sync.title")}
          content={translate("ra.modal.tripletex_sync.content")}
          confirm={translate("ra.action.confirm")}
          cancel={translate("ra.action.cancel")}
          onConfirm={this.handleCreateInvoice}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  refreshView: refreshViewAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate(AccountExtendedRefreshButton))
