// in EmailTemplates.js
import React from "react"
import { SimpleForm, Edit, TextInput, SelectInput } from "react-admin"
import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => record.event || ''

const EmailTemplateEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <SelectInput
          source="event"
          choices={[
            { id: "BASIS", name: "BASIS" },
            { id: "PLUS", name: "PLUS" },
            { id: "PASSWORD_RESET", name: "PASSWORD_RESET" },
            { id: "ACCOUNT_ADMIN_PENDING", name: "ACCOUNT_ADMIN_PENDING" },
            { id: "ABONNEMENT_MAIL_NOTIFICATION", name: "ABONNEMENT_MAIL_NOTIFICATION" },

            { id: "ACCOUNT_CREATE_KB", name: "ACCOUNT_CREATE_KB" },
            { id: "ACCOUNT_CREATE_LDB", name: "ACCOUNT_CREATE_LDB" },
            { id: "ACCOUNT_CREATE_KRD", name: "ACCOUNT_CREATE_KRD" },
            { id: "USER_TOKEN", name: "USER_TOKEN" },
            { id: "USER_CREATE", name: "USER_CREATE" },
            { id: "USER_CREATE_LDB", name: "USER_CREATE_LDB" },
            { id: "USER_CREATE_KB", name: "USER_CREATE_KB" },
            { id: "USER_INVITE", name: "USER_INVITE" },
            { id: "USER_ACTIVATION", name: "USER_ACTIVATION" },
            { id: "USER_REGISTRATION", name: "USER_REGISTRATION" }
          ]}
          allowEmpty
        />
        <TextInput source="subject" />
      </PageFieldset>
      <PageFieldset>
        <TextInput source="to" type="email" />
        <TextInput source="bcc" type="email" />
      </PageFieldset>
      <PageFieldset wide>
        <TextInput multiline component="pre" source="body" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default EmailTemplateEdit
