import React from "react"
import { Show, TabbedShowLayout, Tab, ReferenceManyField, Datagrid, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"

const UserTabs = ({ ...props }) => {
  return (
    <Show title={' - '} {...props} >
      <TabbedShowLayout redirect="edit" title={' - '}>
        <Tab label="resources.account.tabs.subscriptions">
          <ReferenceManyField reference="subscription_summary" target="user_id" addLabel={false}>
            <Datagrid rowClick={id => `/subscription/${id}`}>
              <TextField source="product_name" />
              <TextField source="offer_name" />
              <TextField source="reference" />
              <TextField source="activation_code" />
              <TextField source="address" />
              <DateTimeField label="Opprettet" source="created" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.user_auth.label">
          <ReferenceManyField reference="user_auth" target="user_id" label="Logins" addLabel={false}>
            <Datagrid rowClick="edit">
              <TextField source="email" />
              <DateTimeField source="created" />
              <DateTimeField source="last_login" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="kb.address">
          <ReferenceManyField reference="address" target="user_id" label="Label" addLabel={false}>
            <Datagrid rowClick="edit">
              <TextField source="address" />
              <TextField source="postnumber" />
              <TextField source="city" />
              <TextField source="email" />
              <DateTimeField source="created" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.email_message.label">
          <ReferenceManyField reference="email_message" target="user_id" label="Label" addLabel={false}>
            <Datagrid rowClick="edit">
              <TextField source="subject" />
              <TextField source="body" />
              <DateTimeField source="created" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
}


export default UserTabs
