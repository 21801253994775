import React from 'react';
import PropTypes from 'prop-types';

import Chip from "@material-ui/core/Chip"
import { dateTimeFormatter } from "components/utils"

const DatetimeChipField = ( ({ record, source }) => {

  if (!record || !record[source]) return null
    
  const label = dateTimeFormatter(record[source])
  return <Chip
    size="small"
    label={label}
    title={label}
    />    
  }
)

DatetimeChipField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DatetimeChipField;
