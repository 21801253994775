// in SearchDialogButton.js
import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { refreshView as refreshViewAction } from "ra-core"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import { List, ListItem, ListItemText, Badge, Typography } from "@material-ui/core"
import { push } from "connected-react-router"

import { RpcHeader } from "components/utils"
import stackdriver from 'stackdriver'

import { fetchEnd, fetchStart, showNotification, crudGetMatching } from "react-admin"
import Search from "@material-ui/icons/Search"

import Drawer from "@material-ui/core/Drawer"

const styles = theme => ({
  horizontal: {
    flexDirection: "row",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& svg": { color: "orange" }
  },
  container: {
    width: "500px",
    padding: "1em",
    "& header": {}
  },
  search: {
    width: "100%"
  },
  folder: {
    display: "flex",
    flexDirection: "row"
  },
  folderTitle: {
    flex: 1
  },
  folderCount: {},
  searchitem: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2) ,
    paddingBottom: theme.spacing(2) ,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    cursor: "pointer"
  },
  segment: {
    ...theme.mixins.gutters(),
    width: "100%",
    borderBottom: "solid 1px #aaa"
  }
})

const ResultItem = withStyles(styles)(({ result, classes, redirect }) => (
  <ListItem key={`${result.entity}-${result.id}`} className={classes.searchitem} onClick={redirect}>
    <ListItemText>{result.title} ({result.id})</ListItemText>
    <ListItemText>{result.subtitle}</ListItemText>
  </ListItem>
))

const ResultItemSegment = withStyles(styles)(
  ({ entity, label, count, classes }) =>
    count > 0 && (
      <div>
        <div className={classes.folder}>
          <Typography color="inherit" className={classes.folderTitle}>
            {label}
          </Typography>
          <Typography color="inherit" className={classes.folderCount}>
            <Badge color="secondary" badgeContent={count}>
              <span />
            </Badge>
          </Typography>
        </div>
      </div>
    )
)

class SearchDialogButton extends Component {
  constructor() {
    super()
    this.state = {
      error: false,
      open: false,
      query: "",
      result: []
    }
  }

  toggleDrawer = open => () => {
    this.setState({ open: open })
  }

  redirectToEntity = (result, type = "") => () => {
    this.toggleDrawer(false)()
    if (result && result.id && result.entity) {
      const parts = [
        '',
        result.entity,
        result.id
      ]
      if (type) {
        parts.push(type)
      }
      this.props.push(parts.join('/'))
    }
  }

  handleQuery = e => {
    const query = e.target.value
    if (/^[!]report/.test(query)) {
      stackdriver.report(`Test error report: ${query}`);
    }
    if (/^[!]throw/.test(query)) {
      throw new Error(`Test throw error: ${query}`);
    }
    const queryExpression = `${query}`
    const request = new Request(process.env.REACT_APP_API_URL + "/rpc/search", {
      method: "POST",
      body: JSON.stringify({ query: queryExpression }),
      headers: RpcHeader()
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(json => {
        this.setState({ result: json, query: query })
      })
      .catch(error => {
        showNotification(error.message, "error")
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {

    const accounts = this.state.result.filter(r => r.entity === "account")
    const users = this.state.result.filter(r => r.entity === "user")
    const invoices = this.state.result.filter(r => r.entity === "invoice")
    const domains = this.state.result.filter(r => r.entity === "domain")
    const { classes } = this.props
    return (
      <Fragment>
        <IconButton onClick={this.toggleDrawer(true)} color="inherit">
          <Search />
        </IconButton>
        <Drawer anchor="right" open={this.state.open} onClose={this.toggleDrawer(false)}>
          <div className={classes.container}>
            <TextField defaultValue={this.state.query} autoFocus onChange={this.handleQuery} className={classes.search} placeholder="Søk" type="search" />

            <List>
              <ResultItemSegment entity={"account"} label="Kontoer" count={accounts.length} />
              {accounts.map(r => (
                <ResultItem key={`${r.entity}-${r.id}`} result={r} redirect={this.redirectToEntity(r)} />
              ))}

              <ResultItemSegment entity={"user"} label="Bruker" count={users.length} />
              {users.map(r => (
                <ResultItem key={`${r.entity}-${r.id}`} result={r} redirect={this.redirectToEntity(r)} />
              ))}

              <ResultItemSegment entity={"invoice"} label="Fakturer" count={invoices.length} />
              {invoices.map(r => (
                <ResultItem key={`${r.entity}-${r.id}`} result={r} redirect={this.redirectToEntity(r, "show")} />
              ))}

              <ResultItemSegment entity={"domain"} label="Domene" count={domains.length} />
              {domains.map(r => (
                <ResultItem key={`${r.entity}-${r.id}`} result={r} redirect={this.redirectToEntity(r, "show")} />
              ))}
            </List>
          </div>
        </Drawer>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  refreshView: refreshViewAction,
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchDialogButton))
