import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link as LinkRouter } from "react-router-dom"
import { push } from "connected-react-router"
import compose from "recompose/compose"
import { RpcHeader } from "components/utils"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const load = (location, setItems) => {

  const { pathname } = location

  const request = new Request(process.env.REACT_APP_API_URL + `/rpc/breadcrumb${pathname}`, {
    method: "GET",
    headers: RpcHeader()
  })
  return fetch(request)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        throw new Error(json.error)
      }
      setItems(json)
      // refreshView()
    })
    .catch(() => {})
    .finally(() => {})
}

const PageBreadcrumb = ({  location }) => {
  const classes = useStyles();
  const [items, setItems] = useState()
  useEffect(()=> {

    load(location, setItems)
  }, [ location])

  if (!items) return null
/**
<Paper square className={classes.suggestionsPaper} {...containerProps}>
          {children}
        </Paper>
         */
  return (
    <Paper className={classes.root} >
      <CardContent>
        <Breadcrumbs color="inherit" separator="›" aria-label="breadcrumb">
          {items.map(item => (
              <Link key={item.id} to={`/${item.entity}/${item.id}`} component={LinkRouter} >
                {item.title}
              </Link>
            ))
          }
        </Breadcrumbs>
      </CardContent>
    </Paper>
  )
}

const enhance = compose(
  connect(null, {
    push
  }),
  withRouter
)

export default enhance(PageBreadcrumb)
