// in Offers.js
import React from "react"
import { SimpleForm, Edit, TextInput, NumberInput, ReferenceInput, SelectInput, BooleanInput, DateInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => record.name || ''

const OfferEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <ReferenceInput source="product_id" reference="product" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <NumberInput source="position" />
        <TextInput source="title" />
        <TextInput source="description" />
        <TextInput source="code" />

        <ReferenceInput source="period_unit_id" reference="period_unit" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="period_length" />
        <TextInput source="period_description" />

        <NumberInput source="amount" />
        <ReferenceInput source="vat_id" reference="vat" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <NumberInput source="skip_to_next_period_offset" />
        <NumberInput source="period_start_offset_days" />
        <NumberInput source="create_invoice_offset_days" />
        <NumberInput source="payment_slack_days" />
        <TextInput source="uuid" />
        <TextInput source="image" />

      </PageFieldset>
      <PageFieldset>
        <BooleanInput source="billable" />
        <BooleanInput source="recurring" />
        <BooleanInput source="period_start_use_subscription_date" />
        <BooleanInput source="delivery_address_required" />
        <BooleanInput source="hidden_offer" />

        <DateInput source="start_at" label="Start" />
        <DateInput source="end_at" label="End" />
        <TextInput source="activation_code" />

      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default OfferEdit
