import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';
import './CsvImportInput.css';

const parseCsvData = (string) => {
  var result = Papa.parse(string, {delimiter: ';'});
  if (result && result.data && result.data.length > 0) {
    const clean = result.data.filter( r => r.length === 2)
    return clean
  } else if (result.errors && result.errors.length > 0) {
    return []
  }
}


const Dropzone = ({setData}) => {
  const divEl = useRef(null);
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        setData(parseCsvData(e.target.result))
      }
      reader.readAsText(file)

    })

  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})
  const cls = [
    "CsvImportInput__Dropzone",
    isDragActive ? 'CsvImportInput__Dropzone--active' : ''
  ].join(' ')

  const onPaste = (e) => {
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    setData(parseCsvData(text))
  }

  return <div onPaste={onPaste} ref={divEl} className={cls} {...getRootProps()}>
    <input {...getInputProps()} />
    {
      isDragActive ?
        <p>Drop the file here.</p> :
        <p>
          Drag 'n' drop a csv file here.
          <br/>
          Format:
          <br/>
          <pre>
full name,email<br/>
full name,email
...
          </pre>
        </p>
    }
  </div>
}

const Table = ({data}) => {
  return <table className="Table">
    <tbody>
      { data.map( row => <Row data={row} /> ) }
    </tbody>
  </table>
}

const Row = ({data}) => {
  return <tr className="Table__Row">
    { data.map( cell => <td>{cell}</td> ) }
  </tr>
}

const CsvImportInput = ({ columns, name, label }) => {

  const [data, setData] = useState([])
  const {
    input: { onChange },
    meta: { touched, error }

  } = useField(name);

  useEffect( () => {
    onChange(data)
  }, [data])

  if (!data.length) return <Dropzone setData={setData}/>
  return (
    <div className="CsvImportInput">
      <Table data={data} />
    </div>
    );
  };

  export default CsvImportInput;