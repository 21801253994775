import React, {Children} from "react"
import PropTypes from "prop-types"
import { SimpleForm } from "react-admin"
import PageBreadcrumb from "components/PageBreadcrumb"
import { makeStyles } from '@material-ui/core/styles';
import { SaveButton, DeleteButton, Toolbar } from "react-admin"

const PageFormToolbar = ({actions, ...rest}) => {

  const classes = useStyles()

  return (
    <Toolbar {...rest} className={classes.toolbar}>
      {Children.map(actions, child => React.cloneElement(child, { ...rest }))}

      <SaveButton />
      <DeleteButton {...rest} />

    </Toolbar>
  );
}



const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end !important',
    '& > *': {
      marginLeft: '1em'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > div.MuiToolbar-root': {
      order: '-1'
    },
    '& > div:first-child': {
      display: "grid",
      gridTemplateColumns: "50% 50%",
    },
  },
  rootWithTabs: {
    display: 'flex',
    flexDirection: 'column',
    '& > div.MuiToolbar-root': {
      order: '-1'
    },
    '& > div:first-child': {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      '& > div:last-child': {
        gridColumnEnd: '-1',
        gridColumnStart: '1'
      }
    },
  }
});


const PageForm = ({children, tabs, actions, ...props}) => {
  const classes = useStyles()
  const cls = tabs ? classes.rootWithTabs : classes.root
  if (!props.record) return null
  return [
    <PageBreadcrumb key="1" {...props} />,
    <SimpleForm key="2" {...props} variant="outlined" margin="normal" className={cls} toolbar={<PageFormToolbar actions={actions}/>} >
      {children}
      {tabs}
    </SimpleForm>,
  ]
}

PageForm.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  classes: PropTypes.object,
  wide: PropTypes.bool,
  path: PropTypes.string,
  variant: PropTypes.string,
}

PageForm.displayName = "PageForm"

export default PageForm
