import React from "react"
import { List, Datagrid, TextField, ShowButton } from "react-admin"
import DateTimeField from "components/DateTimeField"
const EventLogList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="entity" />
      <TextField source="subject_id" />
      <TextField source="operation" />
      <TextField source="payload" />
      <TextField source="status" />
      <DateTimeField source="created" />
      <DateTimeField source="completed" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default EventLogList
