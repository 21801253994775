import * as token from "token"

export const RpcHeader = headers => {
  headers = headers || {}
  const authorization = token.get()
  if (authorization && authorization.length > 0) {
    headers["Authorization"] = `Bearer ${authorization}`
  }
  return new Headers({ "Content-Type": "application/json", ...headers })
}

export const dateFormatter = date => {
  const d = new Date(date)
  return d.toLocaleDateString()
}
export const accountFormatName = ({company, id}) => {
  if (!id) return '' 
  return `${company} (${id})`
}

export const dateTimeFormatter = date => {
  if (!date) {
    return "-"
  }
  const d = new Date(date)
  return d.toLocaleString()
}
