import React from "react"
import Typography from "@material-ui/core/Typography"

const roleIdToLabel = id => {
  if (id === 'kb_role_usr') return 'Bruker'
  if (id === 'kb_role_sub') return 'Konto admin'
  if (id === 'kb_role_adm') return 'KR Admin'
  return id
}

const RoleField = ({ record, source }) => <Typography>{ record && roleIdToLabel(record[source]) }</Typography>

export default RoleField
