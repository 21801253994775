
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import SubscriptionLogEdit from "./SubscriptionLogEdit"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest


export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <SubscriptionLogEdit {...sanitizeEditProps(props)} />
  </>
)
