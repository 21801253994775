// in Products.js
import React from "react"
import { List, Datagrid, TextField } from "react-admin"

const ProductList = props => (
  <List {...props}>
    <Datagrid rowClick={id => `/product/${id}`}>
      <TextField source="product_number" />
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="code" />
      <TextField source="url" />
      <TextField source="count" />
    </Datagrid>
  </List>
)

export default ProductList
