import React from "react"
import { ReferenceManyField, SingleFieldList, List, Datagrid, TextField, ShowButton } from "react-admin"
import DatetimeChipField from 'components/DatetimeChipField'
import DateTimeField from "components/DateTimeField"

const EmailMessageList = props => (
  <List {...props} sort={{ field: 'created', order: 'DESC' }}>
    <Datagrid >
      <TextField source="email_template_id" />
      <TextField source="to" />
      <TextField source="subject" />
      <ReferenceManyField label="Delivered" reference="email_status" target="email_message_id">
        <SingleFieldList linkType="show">
          <DatetimeChipField source="created" />
        </SingleFieldList>
      </ReferenceManyField>

      <DateTimeField showTime label="Opprettet" source="created" />

      <ShowButton />
    </Datagrid>
  </List>
)

export default EmailMessageList
