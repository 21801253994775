// in UserAuths.js
import React from "react"
import { List, Datagrid, TextField, ReferenceField, FunctionField } from "react-admin"

const UserAuthList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <ReferenceField source="user_id" reference="user" allowEmpty>
        <FunctionField label="Bruker" render={record => `${record.email || ""} ${record.name || ""}`} />
      </ReferenceField>
      <TextField source="last_login" />
    </Datagrid>
  </List>
)
export default UserAuthList
