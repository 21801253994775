import React from "react"
import PropTypes from "prop-types"
import MuiTab from "@material-ui/core/Tab"
import classnames from "classnames"
import { useTranslate } from 'react-admin'

import { FormInput, Toolbar } from "react-admin"

const sanitizeRestProps = ({ label, icon, value, translate, action, ...rest }) => rest

const hiddenStyle = { display: "none" }

const PageTab = (props) =>  {

  const translate = useTranslate()

  const { children, context, ...rest } = props

  const renderHeader = ({ className, label = '', icon, basePath, value, ...rest }) => {
    return <MuiTab key="header" label={translate(label, { _: label })} icon={icon} value={value} className={classnames("form-tab", className)} {...sanitizeRestProps(rest)} />
  }

  const renderAction = ({ className, label, icon, basePath, value, children, action, ...rest }) => {
    return action ? (
      <Toolbar key="action" {...sanitizeRestProps(rest)}>
        {action}
      </Toolbar>
    ) : null
  }

  const renderContent = ({ children, hidden, action, ...rest }) => (
    <span key="content" style={hidden ? hiddenStyle : null}>
      {React.Children.map(children, input => input && <FormInput input={input} {...sanitizeRestProps(rest)} />)}
    </span>
  )

  if (context === "header") return renderHeader(rest)
  if (context === "content") return renderContent({ children, ...rest })
  if (context === "action") return renderAction({ children, ...rest })

  return null

}

PageTab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.oneOf(["header", "content", "action"]),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  action: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  value: PropTypes.number
}

PageTab.displayName = "PageTab"

export default PageTab
