import React from "react"
import { ReferenceField, BooleanField, Show, TabbedShowLayout, Tab, Datagrid, TextField } from "react-admin"
import PriceField from "components/PriceField"
import ReferenceManyFieldList from "components/ReferenceManyFieldList"
import DateTimeField from "components/DateTimeField"

const OfferTabs = ({ ...props }) => {
  return (
    <Show title={' '} {...props} >
      <TabbedShowLayout redirect="edit" >
        <Tab label="resources.account.tabs.service">
          <ReferenceManyFieldList reference="service_summary" target="offer_id"  >
            <Datagrid rowClick={id => `/service/${id}`}>
              <TextField source="product_name" />
              <ReferenceField source="account_id" reference="account" allowEmpty>
                <TextField source="company" />
              </ReferenceField>
              <TextField source="reference" />
              <PriceField source="amount" />
              <PriceField source="amount_calculated" />
              <TextField source="usage_count" />
              <BooleanField source="auto_assign" />
            </Datagrid>
          </ReferenceManyFieldList>
        </Tab>
          <Tab
            label="resources.account.tabs.users"
          >
            <ReferenceManyFieldList reference="subscription_summary" target="offer_id"  sort={{ field: 'email', order: 'ASC' }} >
              <Datagrid rowClick={id => `/user/${id}`}>
                <ReferenceField source="user_id" reference="user" allowEmpty>
                  <TextField source="email" />
                </ReferenceField>
                <DateTimeField label="Opprettet" source="created" />
                <TextField source="reference" />
                <TextField source="activation_code" />
                <TextField source="address" />
              </Datagrid>
            </ReferenceManyFieldList>
          </Tab>
      </TabbedShowLayout>
    </Show>
  )
}


export default OfferTabs

