
import React from "react"
import { Edit, TextInput, SimpleForm } from "react-admin"

import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => `${record.company}`

const AccountEdit = ({...props}) => {
  const {hasEdit, hasShow, hasCreate,  ...formProps} = props
  return (
    <Edit undoable={false} {...props} title={<Title />}>
      <SimpleForm {...formProps} redirect="edit">
        <PageFieldset>
          <TextInput disabled source="id" />
          <TextInput source="company" />
          <TextInput source="orgnr" />
          <TextInput source="email" />
          <TextInput source="invoice_email" />
        </PageFieldset>
        <PageFieldset>
          <TextInput source="firstname" />
          <TextInput source="lastname" />
          <TextInput source="address" />
          <TextInput source="postnumber" />
          <TextInput source="city" />
          <TextInput source="phone" />
        </PageFieldset>
      </SimpleForm>
    </Edit>
  )
}

export default AccountEdit
