// in Domains.js
import React from "react"
import { Create, TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"
import { accountFormatName } from "components/utils"

const DomainCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <ReferenceInput source="account_id" reference="account" filterToQuery={searchText => ({ company: searchText })} allowEmpty>
          <AutocompleteInput optionText={accountFormatName} />
        </ReferenceInput>
        <TextInput source="domain" />
        <BooleanInput source="status" />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default DomainCreate
