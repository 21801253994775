import React, { Fragment } from "react"
import { List, Datagrid, TextField, Filter, TextInput } from "react-admin"
import AccountExtendedRefreshButton from "./AccountExtendedRefreshButton"

const AccountExtendedFilter = props => (
  <Filter {...props}>
    <TextInput label="ra.filters.account_extended.orgnr" source="orgnr" />
    <TextInput label="ra.filters.account_extended.tripletex_id" source="tripletex_id" />
    <TextInput label="ra.filters.account_extended.tripletex_customer_number" source="tripletex_customer_number" />
  </Filter>
)

const AccountExtendedBulkActionButtons = props => (
  <Fragment>
    <AccountExtendedRefreshButton {...props} />
  </Fragment>
)
const AccountExtendedList = props => (
  <List {...props} filters={<AccountExtendedFilter />} bulkActionButtons={<AccountExtendedBulkActionButtons {...props} />}>
    <Datagrid rowClick="edit">
      <TextField source="orgnr" />
      <TextField source="company" />
      <TextField source="tripletex_id" />
      <TextField source="tripletex_customer_number" />
      <TextField source="status" />
    </Datagrid>
  </List>
)

export default AccountExtendedList
