// in Domains.js
import React from "react"
import { BooleanInput, FunctionField, List, Datagrid, TextField, Filter, BooleanField, ReferenceField } from "react-admin"
import DateTimeField from "components/DateTimeField"
import { accountFormatName } from "components/utils"

const ItemFilter = (props) => (
  <Filter {...props}>    
    <BooleanInput label="Har ett konto" source="account_id" initialValue={true} />
  </Filter>
);

const DomainList = props => (
  <List {...props} filters={<ItemFilter />} perPage={1000}>
    <Datagrid rowClick="edit">
      <ReferenceField source="account_id" reference="account" allowEmpty link={false}>
        <FunctionField label="Konto" render={accountFormatName} />
      </ReferenceField>
      <TextField source="domain" />
      <BooleanField source="status" />
      <DateTimeField source="created" />
    </Datagrid>
  </List>
)

export default DomainList
