import React from "react"
import { Show, SimpleShowLayout, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"
const EventLogTitle = ({ record }) => {
  return <span>EventLog {record ? `"${record.name}"` : ""}</span>
}

const EventLogShow = props => (
  <Show title={<EventLogTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="entity" />
      <TextField source="subject_id" />
      <TextField source="operation" />
      <TextField source="payload" />
      <TextField source="status" />
      <DateTimeField source="created" />
      <DateTimeField source="completed" />
    </SimpleShowLayout>
  </Show>
)

export default EventLogShow
