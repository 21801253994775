
import React from "react"
import { Admin, Resource, Layout } from "react-admin"
import { withStyles, createMuiTheme } from "@material-ui/core/styles"
import { Route } from "react-router-dom"
import dataProvider from "dataProvider"
import authClient from "authClient"
import kbLanguageMessages from "kb-language"
import norwegianMessages from "ra-language-norwegian"
import polyglotI18nProvider from 'ra-i18n-polyglot'

import { AccountList, AccountEditPage, AccountCreate } from "resources/account"
import { AddressCreate } from "./resources/address/index"
import { UserList, UserEditPage, UserCreate } from "./resources/user/index"
import { DomainList, DomainEditPage, DomainCreate } from "./resources/domain"
import { RoleList, RoleEditPage, RoleCreate } from "./resources/role/index"
import { ProductList, ProductEditPage, ProductCreate } from "./resources/product/index"
import { OfferList, OfferEditPage, OfferCreate } from "./resources/offer/index"
import { EmailTemplateList, EmailTemplateEditPage, EmailTemplateCreate } from "./resources/email_template/index"
import { ClientList, ClientEditPage, ClientCreate } from "./resources/client/index"
import { EventLogList, EventLogShow } from "./resources/event_log/index"
import { AuditLogList, AuditLogShow } from "./resources/audit_log/index"
import { SubscriptionLogList, SubscriptionLogEditPage } from "./resources/subscription_log/index"
import { AccountExtendedList, AccountExtendedShow } from "./resources/account_extended/index"
import { EmailMessageList, EmailMessagePage } from "./resources/email_message/index"
import { EmailStatusList, EmailStatusPage } from "./resources/email_status/index"
import { UserAuthList, UserAuthEditPage, UserAuthCreate } from "./resources/user_auth/index"
import { ServiceList, ServiceEditPage } from "./resources/service/index"
import { AccountActivationQueueList } from "./resources/account_activation_queue"
import { SubscriptionEditPage, SubscriptionCreate } from "./resources/subscription/index"
import { PeriodUnitList, PeriodUnitEditPage, PeriodUnitCreate } from "./resources/period_unit/index"
import { VatList, VatCreate } from "./resources/vat/index"
import SsoCallback from "./SsoCallback"
import { InvoiceList, InvoiceShow, InvoiceItemPending } from "./resources/invoice/index"
import LoginPage from "./LoginPage"
import LogoutButton from "./LogoutButton"
import AppLogout from "./Logout"
import AppBar from "./AppBar"
import AppMenu from "./AppMenu"
import './App.css'

import {
  PercentageIcon,
  BuildingIcon,
  UserIcon,
  KeyIcon,
  FileInvoiceDollarIcon,
  FileInvoiceIcon,
  EnvelopeIcon,
  MailBulkIcon,
  BoxesIcon,
  ArrowsAltIcon,
  StreamIcon,
  HandHoldingUsdIcon,
  CalendarCheckIcon,
  UserTagIcon,
  CloudIcon,
  ExternalLinkSquareAltIcon,
  InboxIcon
} from "./AppIcons"

const messages = {
  no: { ...norwegianMessages, ...kbLanguageMessages }
}
//const i18nProvider = locale => messages[locale]
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'no');


const styles = {
  separator: {
    borderBottom: "solid 1px #eee"
  }
}

const myTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#003762"
    },
    primary: {
      main: "#940e19"
    }
  },
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '16px'
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "solid 1px #f9f9f9"
      }
    },
    MuiFormControl: {
      root: {
          width: '512px !important'
      },
    },
    MuiBackdrop: {
      root: {
        bottom: '100px',
        backgroundColor: 'transparent !important;'
      }
    },
    MuiDialog: {
      paper: {
        marginBottom: '150px',
      }
    },
    MuiFilledInput: {
      inputMultiline: {
        fontFamily: 'monospace'
      }
    },
    RaFilterFormInput: {
      body: {
        background: '#fff',
        border: 'solid 1px #aaaaaa57',
        borderRadius: '5px',
        marginRight: '12px',
        alignItems: 'center',
        minHeight: '66px',
        marginBottom: '12px',
      },
    },
    RaFilter: {
      form: {
        alignItems: 'center',
      }
    },
    RaLayout: {
      appFrame: {
        marginTop: '75px !important'
      }
    },
    RaList: {
      root: {
        width: 'auto !important'
      }
    },
    RaNotification: {
      warning: {
        backgroundColor: "#e30013"
      }
    },
    RaEdit: {
      main: {
        flexDirection: 'column-reverse',
        '& > form': {
          display: 'flex',
          flexDirection: 'column-reverse'
        }
      }
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 10000
      }
    }
  }
})

//
const MyLayout = props => <Layout {...props}  appBar={AppBar} menu={AppMenu} />

const MyRoutes = [
  <Route path="/callback" key="callback" component={SsoCallback} noLayout />,
  <Route path="/test" key="test" component={() => <p>TEST</p>} noLayout />,
  <Route path="/logout" key="logout" component={AppLogout} noLayout />
]

const App = () => {
  return (
    <Admin
      theme={myTheme}
      loginPage={LoginPage}
      layout={MyLayout}
      logoutButton={LogoutButton}
      customRoutes={MyRoutes}
      i18nProvider={i18nProvider}
      authProvider={authClient}
      dataProvider={dataProvider}
      title="Leverandørdatabasen"
    >
      <Resource name="account" list={AccountList} edit={AccountEditPage} create={AccountCreate} icon={BuildingIcon} />
      
      <Resource name="user" list={UserList} edit={UserEditPage} create={UserCreate} icon={UserIcon} />
      <Resource name="user_list" list={UserList} icon={UserIcon} />
      
      <Resource name="user_auth" edit={UserAuthEditPage} list={UserAuthList} create={UserAuthCreate} icon={KeyIcon} />
      <Resource name="invoice_item_pending" list={InvoiceItemPending} icon={FileInvoiceIcon} />
      <Resource name="invoice" list={InvoiceList} show={InvoiceShow} icon={FileInvoiceDollarIcon} />

      <Resource name="invoice_pending" list={InvoiceItemPending} icon={FileInvoiceIcon} />

      <Resource name="product" list={ProductList} edit={ProductEditPage} create={ProductCreate} icon={BoxesIcon} />
      <Resource name="product_list" list={ProductList} edit={ProductEditPage} create={ProductCreate} icon={BoxesIcon} />
      
      <Resource name="offer" list={OfferList} edit={OfferEditPage} create={OfferCreate} icon={HandHoldingUsdIcon} />
      <Resource name="offer_list" list={OfferList} edit={OfferEditPage} create={OfferCreate} icon={HandHoldingUsdIcon} />
      
      <Resource name="email_template" list={EmailTemplateList} edit={EmailTemplateEditPage} create={EmailTemplateCreate} icon={MailBulkIcon} />
      <Resource name="client" list={ClientList} edit={ClientEditPage} create={ClientCreate} icon={MailBulkIcon} />
      <Resource name="period_unit" list={PeriodUnitList} edit={PeriodUnitEditPage} create={PeriodUnitCreate} icon={CalendarCheckIcon} />
      <Resource name="domain" list={DomainList} edit={DomainEditPage} create={DomainCreate} icon={CloudIcon} />
      <Resource name="role" list={RoleList} edit={RoleEditPage} create={RoleCreate} icon={UserTagIcon} />
      <Resource name="vat" list={VatList} create={VatCreate} icon={PercentageIcon} />
      <Resource name="email_message" list={EmailMessageList} edit={EmailMessagePage} icon={EnvelopeIcon} />
      <Resource name="email_status" list={EmailStatusList} show={EmailStatusPage} icon={EnvelopeIcon} />
      <Resource name="event_log" list={EventLogList} edit={EventLogShow} icon={ArrowsAltIcon} />
      <Resource name="audit_log" list={AuditLogList} edit={AuditLogShow} icon={StreamIcon} />
      <Resource name="subscription_log" list={SubscriptionLogList} edit={SubscriptionLogEditPage} icon={StreamIcon} />
      <Resource name="account_account_extended_report" list={AccountExtendedList} edit={AccountExtendedShow} icon={ExternalLinkSquareAltIcon} />

      <Resource name="address" create={AddressCreate} />
      <Resource name="service_summary" edit={SubscriptionEditPage} create={SubscriptionCreate} />
      <Resource name="service" list={ServiceList} edit={ServiceEditPage} />
      <Resource name="account_activation_queue_report" list={AccountActivationQueueList} icon={InboxIcon}/>
      <Resource name="subscription" edit={SubscriptionEditPage} />


      <Resource name="subscription_event" />
      <Resource name="subscription_period" />
      <Resource name="subscription_usage" />
      <Resource name="subscription_summary" />
      <Resource name="subscription_usage_summary" />
      <Resource name="user_token" />
      <Resource name="invoice_item" />
      <Resource name="offer_period_selection" />
      <Resource name="offer_subscription_selection" />
      <Resource name="account_user_product_tab" />
      <Resource name="invoice_extended" />
      <Resource name="account_extended" />
    </Admin>
  )
}

export default withStyles(styles)(App)
