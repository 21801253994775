import React from "react"
import { withRouter } from "react-router-dom"
import WarningIcon from "@material-ui/icons/Warning"
import Chip from "@material-ui/core/Chip"

export default withRouter( ({ record, source, ...rest }) => {
  if (!record) return null
  const json = JSON.parse(record[source])
  if (!json) return null

  return json.map( (product) =>
    (<Chip
      key={product.product_id}
      color={product.canceled ? 'secondary' : 'primary' }
      deleteIcon={product.count > 1 ? <WarningIcon /> : null }
      size="small"
      onDelete={product.count > 1 ? () => {} : null}
      label={product.product_name}
      title={product.offer_name}
    />)
)})

