import React from "react"
import IconContentAdd from "@material-ui/icons/Add"
import {
  Button, Create,
  SelectInput, SimpleForm,
  useRefresh,
  useUnselectAll,
  useRedirect
} from "react-admin"
import RpcFormModal, { cleanProps, useRpcFormModal } from "RpcFormModal"
import { push } from "connected-react-router"

const AccountBulkCancelSubscriptionButton = (props) => {

  const redirect = useRedirect();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record = {id: 1}, resource } = props
  if (!record || !record.id) return null

  var account_id
  var selectedIds
  if (resource === 'subscription') {
    account_id = parseInt(record.account_id)
    selectedIds = [record.id]
  }
  else if (resource === 'account') {
    account_id = parseInt(record.id)
    selectedIds = props.selectedIds
  }

  if (!account_id) return null

  const resource_path = `account/${account_id}/subscription_cancel`
  const rest = cleanProps(props)

  const _redirect = () => {
    unselectAll(resource)
    setModalOpen(false)
    redirect(`/account/${account_id}/1`)
  }
  const label = `Avsluta ${selectedIds && selectedIds.length} tjenste(r).`
  return (
    <>
      <Button onClick={toggle} label="ra.action.cancel_subscriptions">
        <IconContentAdd />
      </Button>
      <RpcFormModal modalOpen={modalOpen} toggle={toggle} label={label}>
        <Create {...rest} resource={resource_path} record={null} >
          <SimpleForm redirect={_redirect} initialValues={ {selectedIds } }>
          <SelectInput source="operation" choices={[
              { id: 'cancel', name: 'Avslut' },
              { id: 'delete', name: 'Radera' },
          ]} />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default AccountBulkCancelSubscriptionButton
