// in Domains.js
import React from "react"
import { BooleanInput, ReferenceInput, AutocompleteInput, Edit, TextInput, SimpleForm } from "react-admin"

import PageFieldset from "components/PageFieldset"
import { accountFormatName } from "components/utils"

const Title = ({ record }) => `${record.domain}`

const DomainEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <ReferenceInput source="account_id" reference="account" filterToQuery={searchText => ({ company: searchText })} allowEmpty>
          <AutocompleteInput optionText={accountFormatName} />
        </ReferenceInput>
        <TextInput source="domain" />
        <BooleanInput source="status" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default DomainEdit


