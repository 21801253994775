
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import OfferEdit from "./OfferEdit"
import OfferTabs from "./OfferTabs"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <OfferEdit {...sanitizeEditProps(props)} />
    <OfferTabs {...sanitizeEditProps(props)} />
  </>
)
