import React from "react"
import { Toolbar, SimpleForm, SaveButton, Create, TextInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const AccountCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="ra.action.save"
      redirect="edit"
      submitOnEnter
    />
    <SaveButton
      label="ra.action.save_and_add_new"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);

const AccountCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<AccountCreateToolbar />} redirect="show">
      <PageFieldset>
        <TextInput source="company" />
        <TextInput source="orgnr" />
        <TextInput source="invoice_email" />
      </PageFieldset>
    </SimpleForm>
  </Create>
)

export default AccountCreate
