// in Clients.js
import React from "react"
import { Create, TextInput, SelectInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const ClientCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <TextInput source="name" type="text" />
        <TextInput source="uuid" type="text" />
        <SelectInput
          source="method"
          choices={[
            { id: "implicit", name: "implicit (spa app)" },
            { id: "code", name: "code (backend app)" }
          ]}
          allowEmpty
        />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default ClientCreate
