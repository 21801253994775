import React from "react"
import IconContentAdd from "@material-ui/icons/Add"
import CsvImportInput from 'components/CsvImportInput'
import { priceFormater } from 'components/PriceField'
import {
  Button, Create,
  ReferenceInput,
  SelectInput, SimpleForm,
  useRefresh
} from "react-admin"
import RpcFormModal, { cleanProps, useRpcFormModal } from "RpcFormModal"

const optionRenderer = choice => <span>{choice.offer_name} - {priceFormater(choice.amount_calculated)}</span>

const validateForm = (values) => {
  const errors = {};
  if (!values.users || values.users.length === 0) {
      errors.users = ['The users is required'];
  }
  if (!values.service_id) {
      errors.service_id = ['The service_id is required'];
  } 
  return errors
};
const AccountUserImportButton = (props) => {

  const refresh = useRefresh();
  const [modalOpen, setModalOpen, toggle] = useRpcFormModal()
  const{ record } = props

  if (!record || !record.id) return null
  const rest = cleanProps(props)

  const redirect = (basePath, id, data) => {
    setModalOpen(false)
    refresh()
  }
  const account_id = parseInt(record.id)

  return (
    <>
      <Button onClick={toggle} label="resources.account.actions.invite_bulk">
        <IconContentAdd />
      </Button>
     <RpcFormModal modalOpen={modalOpen} toggle={toggle} label="resources.account.actions.invite_bulk">
      <Create {...rest} resource={`account/${record.id}/invite_users`} record={null}>
          <SimpleForm redirect={redirect} validate={validateForm}>
            <ReferenceInput source="service_id" reference="service_summary" allowEmpty filter={{account_id}}>
              <SelectInput optionText={optionRenderer}/>
            </ReferenceInput>
            <CsvImportInput source="users" name="users" />
          </SimpleForm>
        </Create>
      </RpcFormModal>
    </>
  )
}

export default AccountUserImportButton
