
import React from "react"
import { ReferenceManyField, List } from "react-admin"

import ListActions from "./ListActions"

import { cloneElement } from 'react';
import { ListController } from 'ra-core';
import { ListView } from 'ra-ui-materialui/lib/list';
import { Pagination } from "react-admin"
import { standardPagination } from 'components/StandardList';

export const DumbList = ({...props}) =>
  <ListController {...props}>
    {controllerProps => {
      let { data } = props
      const ids = Object.keys(data || {})
      const total = ids.length
      return <ListView
        {...props}
        // This is important, otherwise bulkActionsToolbar ends up on very top of the page
        classes={{ card: 'relative' }}
        {...Object.assign(controllerProps, { data, ids, total })} 
        {...standardPagination}
        />
    }}
  </ListController>

const sanitizeListProps = ({addLabel, ...rest}) => rest

export default ({actions, bulkActionButtons, ...props}) => {
  const { record, target,resource, children } = props
  if (!record.id) return null;
  const {...listprops} = sanitizeListProps(props)
  const actionslist = actions instanceof Array ? actions: actions ? [actions] : []
  const bulkActionButtonslist = bulkActionButtons instanceof Array ? bulkActionButtons: bulkActionButtons ? [bulkActionButtons] : []
  return (
    <ReferenceManyField addLabel={false} {...props}>
      <DumbList {...listprops} filterDefaultValues={{ [target]: parseInt(record.id) }} 
        actions={
          <ListActions>
            { actionslist && actionslist.map( action => cloneElement(action, {resource,record}) ) }
          </ListActions>
        }
        bulkActionButtons={ bulkActionButtonslist && bulkActionButtonslist.map( action => cloneElement(action, {resource,record}) ) }
      >
        {children}
      </DumbList>
    </ReferenceManyField>
  )
}
