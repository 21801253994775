
import React from "react"
import { ExportButton, TopToolbar, sanitizeListRestProps } from 'react-admin';

export default ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    basePath,
    showFilter,
    maxResults,
    total,
    children,
    ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {children}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
)
