import React, { Component } from "react"
import PropTypes from "prop-types"
import { translate } from "react-admin"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  field: {
    margin: "1em",
    display: "flex",
    flexDirection: "row",
    "& span": {
      flex: 1
    }
  }
}

class InvoiceField extends Component {
  constructor() {
    super()
    this.state = { token: false }
  }

  render() {
    const { record, classes } = this.props

    if (!record && !record.invoice) return null

    return (
      <div>
        {record.invoice.map(invoice => (
          <div key={invoice.id} className={classes.field}>
            <span>
              {invoice.period_value} {invoice.offer_name}{" "}
            </span>
            <span>{invoice.offer_amount} </span>
            <span>
              {invoice.email} {invoice.reference}
            </span>
          </div>
        ))}
      </div>
    )
  }
}

InvoiceField.contextTypes = {
  translate: PropTypes.func
}

InvoiceField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

export default connect(null, {})(withStyles(styles)(translate(InvoiceField)))
