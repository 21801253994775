// in PeriodUnits.js
import React from "react"
import { List, Datagrid, TextField } from "react-admin"

const PeriodUnitList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="code" />
    </Datagrid>
  </List>
)

export default PeriodUnitList
