import React from "react"
import Typography from "@material-ui/core/Typography"

const formatter = new Intl.NumberFormat("nb", {
  style: "currency",
  currency: "NOK",
  minimumFractionDigits: 2
})

export const priceFormater = (value) => formatter.format(value)

const PriceField = ({ record, source }) => <Typography>{record && source && record[source] ? priceFormater(record[source]) : "-"}</Typography>

export default PriceField
