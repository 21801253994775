// in Users.js
import React from "react"

import { Datagrid, ReferenceField, TextField, List } from "react-admin"
import ArchivedField from "components/ArchivedField"
import DateTimeField from "components/DateTimeField"
// import StandardList from "components/StandardList"

const UserList = props => (
  <List {...props} perPage={25}>
    <Datagrid rowClick={id => `/user/${id}`}>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="role_name" />
      <TextField source="company" />
      <DateTimeField label="Opprettet" source="created" />
      <DateTimeField showTime label="Siste innlogging" source="last_login" />
      <ArchivedField />
    </Datagrid>
  </List>
)

export default UserList
