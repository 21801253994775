import StackdriverErrorReporter from 'stackdriver-errors-js';

const stackdriver = new StackdriverErrorReporter();
stackdriver.start({
    key: 'AIzaSyBIECTksjI9CfRBGtDgAOwPoQNkl91wkoU',
    projectId: 'kr-admin-227708',

    // The following optional arguments can also be provided:

    // service: myServiceName,
    // Name of the service reporting the error, defaults to 'web'.

    // version: myServiceVersion,
    // Version identifier of the service reporting the error.

    // reportUncaughtExceptions: false
    // Set to false to prevent reporting unhandled exceptions, default: `true`.

    // reportUnhandledPromiseRejections: false
    // Set to false to prevent reporting unhandled promise rejections, default: `true`.

    // disabled: true
    // Set to true to not send error reports, this can be used when developing locally, default: `false`.

    // context: {user: 'user1'}
    // You can set the user later using setUser()
});

export default stackdriver;