// in Clients.js
import React from "react"
import { List, Datagrid, TextField, DateField } from "react-admin"

const ClientList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="uuid" />
      <TextField source="method" />
      <DateField source="created" />
    </Datagrid>
  </List>
)

export default ClientList
