
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import AccountEdit from "./AccountEdit"
import AccountTabs from "./AccountTabs"


const sanitizeShowProps = ({hasEdit, hasList, hasShow, hasCreate, ...rest}) => rest
const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest


export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <AccountEdit {...sanitizeEditProps(props)} />
    <AccountTabs {...sanitizeShowProps(props)} hasEdit={false} />
  </>
)
