// in Users.js
import React from "react"

import { Create, ReferenceInput, SelectInput, TextInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const UserCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <TextInput source="email" />
        <ReferenceInput label="Rolle" source="role_id" reference="role" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </PageFieldset>
    </PageForm>
  </Create>
)
export default UserCreate
