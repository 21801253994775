// in PeriodUnits.js
import React from "react"
import { SimpleForm, Edit, TextInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => record.name || ''

const PeriodUnitEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <TextInput source="name" />
        <TextInput source="code" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default PeriodUnitEdit
