import React from "react"
import { SelectInput } from "react-admin"

const amount_types = [
  { id: "DEFAULT", name: "Beløp från tillbud (default)" },
  { id: "ABSOLUT", name: "Ange absolut beløp (absolute)" },
  { id: "PERCENT", name: "Ange absolut procent (procent)" },
  { id: "DELTA", name: "Ange fast forskjell (delta)" }
]

export default (props) => <SelectInput {...props} choices={amount_types} />
