// in roles.js
import React from "react"
import { Create, TextInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const RoleCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <TextInput source="id" />
        <TextInput source="name" />
      </PageFieldset>
    </PageForm>
  </Create>
)

export default RoleCreate
