import React, { Fragment, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCancel from "@material-ui/icons/Cancel"
import { useTranslate } from 'ra-core'
import {
  Button
} from "react-admin"

export const useRpcFormModal = (initialMode = false) => {
  const [modalOpen, setModalOpen] = useState(initialMode)
  const toggle = () => setModalOpen(!modalOpen)
  return [modalOpen, setModalOpen, toggle]
}

const RpcFormModal = ({label, icon, children, modalOpen, toggle}) => {

  const translate = useTranslate()  
  const labelTxt = label && /^[a-z.]+$/.test(label) ? translate(label) : label
  return (
    <Fragment>
      <Dialog fullWidth maxWidth="md" open={modalOpen} onClose={toggle} aria-label={labelTxt}>
        <DialogTitle>{labelTxt}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={toggle}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default RpcFormModal

export const cleanProps = ({basePath, id, history, location, resource, options, hasList, hasEdit, hasShow, hasCreate, record, redirect, saving, ...rest}) => ({basePath, id, history, location, resource, options, hasList, hasEdit, hasShow, hasCreate, record, redirect, saving})