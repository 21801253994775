import React, { Fragment } from "react";
import AccountInfoField from "components/AccountInfoField";
import AddressInfoField from "components/AddressInfoField";
import ProductInfoField from "components/ProductInfoField";
import SubscriptionInfoField from "components/SubscriptionInfoField";
import SubscriptionPeriodInfoField from "components/SubscriptionPeriodInfoField";
import { accountFormatName } from "components/utils";
import { AutocompleteInput, BooleanInput, Datagrid, ExportButton, Filter, List, ReferenceInput, sanitizeListRestProps, SelectInput, TextField, TextInput, TopToolbar } from "react-admin";
import InvoiceAllButton from "./InvoiceAllButton";
import InvoiceCreateButton from "./InvoiceCreateButton";

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      })}
      <ExportButton
        disabled={total === 0}
        resource={`${resource}`}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />

      <InvoiceAllButton 
        resource={`${resource}_invoice`}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
      />
  </TopToolbar>
);

const InvoicePendingReportBulkActionButtons = props => (
  <Fragment>
    <InvoiceCreateButton {...props} />
  </Fragment>
)
const ItemFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="account_id" reference="account" filterToQuery={company => ({ company })} resettable allowEmpty>
      <AutocompleteInput optionText={accountFormatName} />
    </ReferenceInput>
    <ReferenceInput source="user_id" reference="user" filterToQuery={email => ({ email })} resettable allowEmpty>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput source="product_id" reference="product" resettable allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput source="offer_id" reference="offer" resettable allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <TextInput label="Orgnr" source="orgnr"  />
    <TextInput label="Bruker email" source="email"  />
    <TextInput label="Faktura email" source="invoice_email"  />
    <TextInput label="Referense" source="reference"  />
    <TextInput label="Firmanavn" source="company"  />
    <SelectInput label="Produkt" source="product"  />
    <BooleanInput source="billed"  />
  </Filter>
);

const InvoiceItemPending = props => (
  <List {...props} filters={<ItemFilter />} actions={<ListActions />} bulkActionButtons={<InvoicePendingReportBulkActionButtons />}>
    <Datagrid rowClick={(id, basePath, record) => `/subscription/${record['subscription_id']}`}>
      <SubscriptionInfoField source="subscription_id" label="Abonnement"/>
      <SubscriptionPeriodInfoField source="subscription_id" label="Period status"/>
      <AccountInfoField source="orgnr" label="Firma"/>
      <ProductInfoField source="offer_name" label="Produkt"/>
      <AddressInfoField label="Adresse"/>
      <TextField source="reference" />
      <TextField source="email" label="Bruker"/>
    </Datagrid>
  </List>
)

export default InvoiceItemPending
