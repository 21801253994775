import React, { Component } from "react"

import queryString from "query-string"

const ssoUrl = route => {
  const params = {
    returnUrl: process.env.REACT_APP_ADMIN_URL + "/#/callback/"
  }
  return `${process.env.REACT_APP_SSO_URL}/${process.env.REACT_APP_ADMIN_SSO_CLIENT_ID}/${route}?${queryString.stringify(params)}`
}

class CustomLoginPage extends Component {
  componentDidMount = () => {
    window.location.href = ssoUrl("sso_login")
  }

  render() {
    return (
      <>
        <p>Redirect to sso..</p>
      </>
    )
  }
}

export default CustomLoginPage
