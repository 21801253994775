// in Vats.js
import React from "react"
import { SimpleForm, Create, TextInput } from "react-admin"

import PageFieldset from "components/PageFieldset"

const VatCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <PageFieldset>
        <TextInput source="id" />
        <TextInput source="value" />
        <TextInput source="number" />
        <TextInput source="name" />
        <TextInput source="description" />
      </PageFieldset>
    </SimpleForm>
  </Create>
)

export default VatCreate
