import React, { Fragment, useCallback, useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { Button, SaveButton, SimpleForm } from 'react-admin';
import { useForm } from 'react-final-form';
import { connect } from "react-redux";

const CreateInModalButton = ({ handleSubmit, handleSubmitWithRedirect, children, label, clearForm, ...props }) => {
    const [_showDialog, setShowDialog] = useState(false); // no-unused-vars
    const form = useForm();

    const formName = () => `${props.entity}-modal-form`
    const isSubmitting =  () => {}

    const handleOpenClick = () => {
      setShowDialog(true)
    }

    const handleCloseClick = () => {
      setShowDialog(false)
      clearForm(formName())
    }

    const handleSaveClick = useCallback(() => {
        form.change('average_note', 10);    
        handleSubmitWithRedirect('edit');
    }, [form, handleSubmitWithRedirect]);

    return <Fragment>
      <Button onClick={handleOpenClick} label={label}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={handleOpenClick} onClose={handleCloseClick} aria-label={label}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <SimpleForm form={formName} name={formName} resource={props.entity} onSubmit={handleSubmit} toolbar={null}>
            {children && React.Children.map(children, child => React.cloneElement(child))}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <SaveButton saving={isSubmitting} onClick={handleSaveClick} />
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>

    //return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};


const mapStateToProps = (state) => ({

})

const clearForm = formName => {
  return {
    type: "@@react-final-form/DESTROY",
    meta: {
      form: [formName]
    }
  }
}

const mapDispatchToProps = {
  clearForm
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInModalButton)
