import React from "react"
import { Show, SimpleShowLayout, TextField } from "react-admin"

import DateTimeField from "components/DateTimeField"
const AuditLogTitle = ({ record }) => {
  return <span>AuditLog {record ? `"${record.name}"` : ""}</span>
}

const AuditLogShow = props => (
  <Show title={<AuditLogTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="segment" />
      <TextField source="entity" />
      <TextField source="user" />
      <TextField source="operation" />
      <TextField source="payload" />
      <DateTimeField source="created" />
    </SimpleShowLayout>
  </Show>
)

export default AuditLogShow
