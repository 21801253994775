// in Offers.js
import React from "react"
import { List, Datagrid, TextField, ReferenceField } from "react-admin"
import PriceField from "components/PriceField"
import DateTimeField from "components/DateTimeField"
const OfferList = props => (
  <List {...props}>
    <Datagrid rowClick={id => `/offer/${id}`}>
      <TextField source="product_name" />
      <TextField source="position" />
      <ReferenceField source="period_unit_id" reference="period_unit" link={false}>
        <TextField source="name" />
      </ReferenceField>

      <TextField source="code" />
      <TextField source="name" />
      <PriceField source="amount" />
      <DateTimeField source="start_at" />
      <DateTimeField source="end_at" />
      <TextField source="count" />
    </Datagrid>
  </List>
)

export default OfferList
