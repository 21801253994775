
import React from "react"
import { Show, TabbedShowLayout, Datagrid, TextField, ReferenceField } from "react-admin"
import Tab from "components/PageTab"
import DoneIcon from '@material-ui/icons/Done';
import DateTimeField from "components/DateTimeField"
import AccountBulkCancelSubscriptionButton from 'resources/account/AccountBulkCancelSubscriptionButton'
import ReferenceManyFieldList from "components/ReferenceManyFieldList"

const postRowStyle = (record) => ({
  opacity: record.processed ? '1': '0.5',
});


const TrueFalse = ({ source, record = {} }) => {
  return <span>{
    record[source] ? <DoneIcon/> : ''
  }</span>
}

const FalseTrue = ({ source, record = {} }) => {
  return <span>{
    !record[source] ? <DoneIcon/> : ''
  }</span>
}

const SubscriptionTabs = ({ ...props }) => {
  return (
    <Show title={' - '} {...props} >
      <TabbedShowLayout redirect="edit" title={' - '}>
        <Tab label="resources.subscription.label">
          <ReferenceManyFieldList sort={{ field: 'id', order: 'DESC' }} reference="subscription_period" target="subscription_id" label="Abonnemangsperioder" addLabel={false} actions={<AccountBulkCancelSubscriptionButton />}>
            <Datagrid rowStyle={postRowStyle} >
              <DateTimeField label="Period start" source="datestamp" />

              <TrueFalse source="processed" />
              <TrueFalse source="billed" />
              <TrueFalse source="paid" />
              <FalseTrue source="canceled" />

              <ReferenceField source="" reference="user" allowEmpty>
                <TextField source="email" />
              </ReferenceField>

              <DateTimeField label="Opprettet" source="created" />
            </Datagrid>
          </ReferenceManyFieldList>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}


export default SubscriptionTabs
