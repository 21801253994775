
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import ServiceEdit from "./ServiceEdit"
import ServiceTabs from "./ServiceTabs"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <ServiceEdit {...sanitizeEditProps(props)} />
    <ServiceTabs {...sanitizeEditProps(props)} />
  </>
)
