module.exports = {
  resources: {
    email_message: {
      name: "E-postkø |||| E-postkø",
      label: "E-postkø",
      fields: {}
    },
    email_status: {
      name: "Sendt e-post |||| Sendt e-post",
      label: "Sendt e-post",
      fields: {}
    },
    subscription_usage: {
      name: "Abonnement |||| Abonnementer",
      label: "Abonnementer",
      fields: {
        subscription: "Abonnement",
        subscription_id: "Abonnement",
        offer_name: "Tilbud",
        usage_count: "Antall brukere",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    service: {
      name: "Tjeneste |||| Tjenester",
      label: "Tjenester",
      fields: {
        usage_count: "Antall brukere",
        reference: "Referanse",
        offer_id: "Tilbud",
        amount: "Beløp",
        amount_use_default: "La stå tomt for å bruke standardbeløp",
        comments: "Kommentar",
        subscription: "Abonnement",
        subscription_id: "Abonnement",
        offer_name: "Tilbud",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    offer_subscription_selection: {
      fields: {
        subscription_id: "Abonnement"
      }
    },
    subscription: {
      name: "Abonnement |||| Abonnementer",
      label: "Abonnement",
      fields: {
        usage_count: "Antall brukere",
        reference: "Referanse",
        offer_id: "Tilbud",
        amount: "Beløp",
        amount_use_default: "La stå tomt for å bruke standardbeløp",
        comments: "Kommentar",
        created: "Opprettet",
        updated: "Oppdatert"
      },
      tabs: {
        reference: "Referanse",
        offer_id: "Tilbud",
        amount: "Beløp",
        amount_use_default: "La stå tomt for å bruke standardbeløp",
        comments: "Kommentar",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    subscription_period: {
      fields: {
        canceled: "Renew",
        processed: "Current / Processed",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    subscription_summary: {
      name: "Abonnement |||| Abonnementer",
      label: "Abonnement",
      fields: {
        usage_count: "Antall brukere",
        reference: "Referanse",
        offer_id: "Tilbud",
        amount: "Beløp",
        amount_use_default: "La stå tomt for å bruke standardbeløp",
        comments: "Kommentar",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    service_summary: {
      name: "Tjenste |||| Tjenster",
      label: "Tjenste",
      fields: {
        offer_name: "Tilbud",
        product_name: "Produkt",
        usage_count: "Antall abonnementer",
        reference: "Referanse",
        offer_id: "Tilbud",
        service_id: "Tjeneste",
        amount: "Beløp",
        amount_calculated: "Beregnet beløp",
        comments: "Kommentar",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    offer: {
      name: "Tilbud |||| Tilbud",
      label: "Tilbud",
      fields: {
        usage_count: "Antall brukere",
        name: "Navn",
        code: "Kode",
        title: "Tittel",
        description: "Beskrivelse",
        amount: "Beløp",
        vat_id: "Mva",
        product_id: "Produkt",
        period_unit_id: "Periodetype",
        start_at: "Start",
        end_at: "Slutt",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    offer_list: {
      name: "Tilbud |||| Tilbud",
      label: "Tilbud",
      fields: {
        usage_count: "Antall brukere",
        name: "Navn",
        product_name: "Produkt",
        code: "Kode",
        title: "Tittel",
        description: "Beskrivelse",
        amount: "Beløp",
        vat_id: "Mva",
        product_id: "Produkt",
        period_unit_id: "Periodetype",
        start_at: "Start",
        end_at: "Slutt",
        count: "Antal ab.",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    domain: {
      name: "Domene |||| Domener",
      label: "Domene",
      fields: {
        account_id: "Konto",
        domain: "Domene",
        status: "Status",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    period: {
      name: "Periode |||| Perioder",
      label: "Periode",
      fields: {}
    },
    period_unit: {
      name: "Periodeenhet |||| Periodeenheter",
      label: "Periodeenheter",
      fields: {}
    },
    client: {
      name: "Client |||| Clients",
      label: "Client",
      fields: {
        secret: "Secret",
        name: "Navn",
        method: "Metode",
        created: "Opprettet"
      }
    },
    address: {
      name: "Adresse |||| Adresser",
      label: "Adresse",
      fields: {
        user_id: "Bruker",
        address: "Adresse",
        postnumber: "Postnummer",
        city: "Poststed",
        email: "E-post",
        phone: "Telefonnummer",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    invoice: {
      name: "Sendte faktura |||| Sendte fakturer",
      label: "Sendte fakturaer",
      fields: {
        user_id: "Bruker",
        address: "Adresse",
        postnumber: "Postnummer",
        city: "Poststed",
        email: "E-post",
        phone: "Telefonnummer",
        created: "Opprettet",
        updated: "Oppdatert",
        unit_title: "Tittel",
        unit_subtitle: "Undertittel",
        user_email: "E-post",
        unit_vat_name: "Mva",
        unit_amount: "Beløp"
      }
    },
    invoice_item: {
      name: "Fakturarad |||| Fakturarader",
      label: "Fakturarad",
      fields: {
        unit_title: "Tittel",
        unit_subtitle: "Undertittel",
        user_email: "E-post",
        unit_vat_name: "Mva",
        unit_amount: "Beløp"
      }
    },
    invoice_item_pending: {
      name: "Oversikt |||| Oversikt",
      label: "Oversikt",
      fields: {
        user_id: "Bruker",
        account_id: "Konto",
        reference: "Referanse",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    invoice_pending: {
      name: "Faktura-eksport |||| Faktura-eksport",
      label: "Faktura-eksport",
      fields: {
        user_id: "Bruker",
        account_id: "Konto",
        reference: "Referanse",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    account_account_extended_report: {
      name: "Tripletex",
      label: "Tripletex",
      fields: {
        domain: "Domene",
        company: "Firmanavn",
        address: "Adresse",
        postnumber: "Postnummer",
        city: "Poststed",
        orgnr: "Orgnr",
        status: "Status",
        tripletex_id: "Tripletex ID",
        tripletex_customer_number: "Tripletex kundenummer",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    account_activation_queue_report: {
      name: "Medlemsforespørsel",
      label: "Medlemsforespørsel",
      actions: {
        approve: 'Godkjenn',
        reject: 'Avvise',
      },
      fields: {
        email: "Epost",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    account: {
      name: "Konto |||| Kontoer",
      label: "Firma",
      actions: {
        invite: "Invitere bruker",
        invite_bulk: "Last opp bruker",
        service: "Legg til tjeneste",
        subscription: "Legg til abonnement",
      },
      tabs: {
        details: "Firma",
        address: "Adresser",
        users: "Bruker",
        subscriptions: "Abonnementer",
        subscription_period: "Abonnementsperiode",
        subscription_event: "Abonnementshendelse",
        service: "Tjenster",
        invoice_pending: "Ikke fakturert",
        invoice: "Fakturaer",
        account_activation_queue_report: 'Medlemsforespørsel'
      },
      fields: {
        domain: "Domene",
        firstname: "Fornavn",
        lastname: "Etternavn",
        user_id: "Bruker",
        email: "Epost",
        invoice_email: "Epost for fakturering",
        company: "Firmanavn",
        address: "Adresse",
        postnumber: "Postnummer",
        city: "Poststed",
        fakturaemail: "Fakturaepost",
        orgnr: "Orgnr",
        phone: "Telefonnummer",
        comments: "Kommentar",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    product: {
      name: "Produkt |||| Produkter",
      label: "Produkt",
      fields: {
        product_number: "Produktnummer",
        name: "Navn",
        title: "Tittel",
        description: "Beskrivelse",
        code: "Kode",
        url: "URL"
      }
    },
    product_list: {
      name: "Produkt |||| Produkter",
      label: "Produkt",
      fields: {
        product_number: "Produktnummer",
        name: "Navn",
        title: "Tittel",
        description: "Beskrivelse",
        code: "Kode",
        url: "URL",
        count: "Antal ab."
      }
    },
    account_user_product_tab: {
      name: "Bruker |||| Bruker",
      label: "Bruker",
      fields: {
        products: "Produkter",
        email: "E-post",
        name: "Navn",
        period_name: "Periode",
        service: "Tjeneste",
        service_id: "Tjeneste",
        amount: "Beløp",
        amount_calculated: "Beregnet beløp",
        start_at: "Start",
        end_at: "Slutt",
        created: "Opprettet",
        updated: "Oppdatert"
      }
    },
    user: {
      product_id: "Produkter",
      name: "Bruker |||| Bruker",
      label: "Bruker",
      fields: {
        email: "E-post",
        active: "Aktiv",
        display_name: "Navn",
        role_id: "Rolle",
        account_id: "Konto",
        subscriptions: "Abonnementer"
      }
    },
    user_list: {
      product_id: "Produkter",
      name: "Bruker |||| Bruker",
      label: "Bruker",
      fields: {
        email: "E-post",
        active: "Aktiv",
        display_name: "Navn",
        role_id: "Rolle",
        account_id: "Konto",
        subscriptions: "Abonnementer"
      }
    },
    user_token: {
      label: "Kode for gjennoppretting av passord"
    },
    user_auth: {
      name: "Login |||| Login",
      label: "Login",
      fields: {
        user_id: "Bruker",
        email: "E-post",
        password: "Passord",
        token: "Aktiviseringskode",
        last_login: "Siste innlogging",
        activated: "Aktivert"
      }
    },
    email_template: {
      name: "E-postmal |||| E-postmaler",
      label: "E-postmal",
      fields: {}
    },
    vat: {
      name: "Merverdiavgift |||| Merverdiavgift",
      label: "Merverdiavgift",
      fields: {
        vat: "mva"
      }
    },
    role: {
      name: "Rolle |||| Rolle",
      label: "Rolle",
      fields: {
        name: "Navn"
      }
    },
    billing_info: {
      name: "Fakturaadresse |||| Fakturaadresse",
      label: "Fakturaadresse",
      fields: {
        company: "Firmanavn",
        address: "Adresse",
        postnumber: "Postnummer",
        city: "Poststed",
        fakturaemail: "Fakturaepost"
      }
    },
    audit_log: {
      name: "Audit |||| Audit",
      label: "Audit",
      fields: {}
    },
    subscription_log: {
      name: "Abonnement |||| Abonnement",
      label: "Abonnement",
      fields: {}
    },
    event_log: {
      name: "Integrasjon |||| Integrasjon",
      label: "Integrasjon",
      fields: {}
    }
  },
  jwt: { claims: { user_auth_uuid: "UUID" } },
  kb: {
    name: "Navn",
    details: "Detaljer",
    contact: "Kontakt",
    address: "Addresse",
    updated: "Oppdatert",
    created: "Opprettet"
  },
  ra: {
    filters: {
      email_status: {
        message: "Søk i melding"
      },
      account_extended: {
        orgnr: "Søk etter orgnr",
        tripletex_id: "Søk etter Tripletex ID",
        tripletex_customer_number: "Søk etter Tripletex kundenummer"
      }
    },
    action: {
      bulk_actions: 'Rediger flere',
      download: "Laste ned",
      delete: "Slett",
      export: "Eksport",
      show: "Vise",
      list: "Liste",
      save: "Lagre",
      save_and_add_new: "Lagre og opprett ny",
      create: "Opprett",
      undo: "Angre",
      edit: "Redigere",
      send: "Sende",
      cancel: "Avbryt",
      confirm: "Bekrefte",
      refresh: "Oppdatere",
      add_filter: "Legg til filter",
      remove_filter: "Fjerne filter",
      back: "Tilbake",
      invite: "Invitere bruker",
      subscription_event: "Abonnementshendelse",
      add_subscriptions: "Legg til tjeneste",
      cancel_subscriptions: "Avslutte tjeneste",
      move_user: "Flytte brukere",
      invoice: "Opprette faktura",
      setpassword: "Sett Passord",
      send_password_token: "Send passordlenke",
      tripletex_sync: "Oppdatere Tripletex info",
      sort: "Sortere",
      clear_input_value: 'Tilbakestill inngangsverdien',
      unselect: "Avvelg"
    },
    boolean: {
      true: "Ja",
      false: "Nei"
    },
    modal: {
      set_password: {
        title: "Sett Passord",
        label: "Sett Passord",
        content: "Bekreft nytt passord"
      },
      create_invoice: {
        title: "Opprett faktura",
        label: "Opprett faktura",
        content: "Bekreft at du vil sende faktura til valgte adresser"
      },
      tripletex_sync: {
        title: "Oppdatere Tripletex info",
        label: "Oppdatere Tripletex info",
        content: "Bekreft at du vil oppdatere Tripletex-info for valgte kunder"
      }
    },
    page: {
      list: "%{name}",
      edit: "%{name}",
      show: "%{name}",
      create: "Lagre %{name}",
      delete: "Redigere %{name}",
      dashboard: "Oversikt",
      not_found: "ikke funnet"
    },
    input: {
      file: {
        upload_several: "Legg noen filer for å laste opp, eller klikk for å velge en.",
        upload_single: "Legg en file for å laste opp, eller klikk for å velge en"
      },
      image: {
        upload_several: "Legg til noen bilder for å laste opp, eller klikk for å velge en.",
        upload_single: "Legg til et bilde for å laste opp, eller klikk for å velge en"
      }
    },
    message: {
      yes: "Ja",
      no: "Nei",
      are_you_sure: "Er du sikker?",
      about: "Om",
      not_found: "Feil url, eller ugyldig link",
      invalid_form: "Ugyldig skjema",
      delete_title: 'Slette',
      delete_content: 'Er du sikker? Data vil gå tapt.'
    },
    navigation: {
      no_results: "Ingen resultater",
      page_rows_per_page: 'Rader per side',
      page_out_of_boundaries: "Side nummer %{page} av grenser",
      page_out_from_end: "Kan ikke gå videre etter siste siden.",
      page_out_from_begin: "Kan ikke gå videre før første side",
      page_range_info: "%{offsetBegin}-%{offsetEnd} av %{total}",
      next: "Neste",
      prev: "Forrige"
    },
    auth: {
      username: "Brukernavn",
      password: "Passord",
      sign_in: "Logg inn",
      sign_in_error: "Godkjenning mislyktes, prøv på nytt",
      logout: "Logg ut"
    },
    notification: {
      updated: "Element oppdatert",
      created: "Element opprettet",
      deleted: "Element slettet",
      item_doesnt_exist: "Elementet eksisterer ikke",
      http_error: "Kommunikasjonsfeil med server"
    },
    validation: {
      required: "Obligatorisk",
      minLength: "Minimum %{min} tegn er påkrevd.",
      maxLength: "Minimum %{max} tegn er påkrevd.",
      minValue: "Må være minst %{min}",
      maxValue: "Må være høyst %{max}",
      number: "Må være et nummer",
      email: "Må være gyldig e-post"
    }
  }
}
