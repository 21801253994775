import React from "react"
import DateTimeField from "components/DateTimeField"
import { Datagrid, List, ReferenceField, TextField } from "react-admin"
import AccountActivationButton from "./AccountActivationButton"

const AccountActivationButtonList = props => (
  <>
      <AccountActivationButton approve={true} {...props} />
      <AccountActivationButton approve={false} {...props} />
  </>
);

const AccountActivationQueueList = props => (
  <List {...props} bulkActionButtons={<AccountActivationButtonList />}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Konto" source="account_id" reference="account" allowEmpty>
        <TextField source="company" />
      </ReferenceField>
      <ReferenceField label="Bruker" source="user_id" reference="user" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="user_email" />
      <TextField source="subject_entity" />
      <TextField source="title" />
      <TextField source="subtitle" />
      <DateTimeField source="created" />
    </Datagrid>
  </List>
)

export default AccountActivationQueueList
