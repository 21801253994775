import React from "react"
import DateTimeField from "components/DateTimeField"

export default ({ record = {} }) => (
    <span>
        #{record['subscription_id']}<br/>
        Start <DateTimeField record={record} source="datestamp" label="Start datum"/><br/>
        Neste {record['next_datestamp'] ? 
            <DateTimeField record={record} source="next_datestamp" label="Neste datum"/>
            :
            <span>-</span>
        }
    </span>
);
