import React, {} from "react"
import PropTypes from "prop-types"
import { FormInput } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
});

const PageFieldset = ({ children, basePath, record, resource, variant, margin }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {React.Children.map(children, input => (
        <FormInput basePath={basePath} input={input} record={record} resource={resource} variant={variant} margin={margin} />
      ))}{" "}
    </div>
  )
}

PageFieldset.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  classes: PropTypes.object,
  wide: PropTypes.bool,
  path: PropTypes.string,
  variant: PropTypes.string,
}

PageFieldset.displayName = "PageFieldset"

export default PageFieldset
