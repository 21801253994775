import React from "react"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faKey,
  faPercentage,
  faCalendarWeek,
  faBuilding,
  faUser,
  faFileInvoiceDollar,
  faFileInvoice,
  faEnvelope,
  faMailBulk,
  faBoxes,
  faArrowsAlt,
  faStream,
  faHandHoldingUsd,
  faCalendarCheck,
  faUserTag,
  faCloud,
  faExternalLinkSquareAlt,
  faExternalLinkAlt,
  faInbox,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faKey,
  faPercentage,
  faCalendarWeek,
  faBuilding,
  faUser,
  faFileInvoiceDollar,
  faFileInvoice,
  faEnvelope,
  faMailBulk,
  faBoxes,
  faArrowsAlt,
  faStream,
  faHandHoldingUsd,
  faCalendarCheck,
  faUserTag,
  faCloud,
  faExternalLinkSquareAlt,
  faExternalLinkAlt,
  faInbox,
  faExclamationTriangle
)

const FontAwesomeIconWrapper = ({ titleAccess, icon }) => <FontAwesomeIcon icon={icon} />

export const PercentageIcon = () => <FontAwesomeIconWrapper icon={faPercentage} />
export const CalendarWeekIcon = () => <FontAwesomeIconWrapper icon={faCalendarWeek} />
export const BuildingIcon = () => <FontAwesomeIconWrapper icon={faBuilding} />
export const UserIcon = () => <FontAwesomeIconWrapper icon={faUser} />
export const FileInvoiceDollarIcon = () => <FontAwesomeIconWrapper icon={faFileInvoiceDollar} />
export const FileInvoiceIcon = () => <FontAwesomeIconWrapper icon={faFileInvoice} />
export const EnvelopeIcon = () => <FontAwesomeIconWrapper icon={faEnvelope} />
export const MailBulkIcon = () => <FontAwesomeIconWrapper icon={faMailBulk} />
export const BoxesIcon = () => <FontAwesomeIconWrapper icon={faBoxes} />
export const ArrowsAltIcon = () => <FontAwesomeIconWrapper icon={faArrowsAlt} />
export const StreamIcon = () => <FontAwesomeIconWrapper icon={faStream} />
export const HandHoldingUsdIcon = () => <FontAwesomeIconWrapper icon={faHandHoldingUsd} />
export const CalendarCheckIcon = () => <FontAwesomeIconWrapper icon={faCalendarCheck} />
export const UserTagIcon = () => <FontAwesomeIconWrapper icon={faUserTag} />
export const CloudIcon = () => <FontAwesomeIconWrapper icon={faCloud} />
export const ExternalLinkSquareAltIcon = () => <FontAwesomeIconWrapper icon={faExternalLinkSquareAlt} />
export const ExternalLinkAltIcon = () => <FontAwesomeIconWrapper icon={faExternalLinkAlt} />
export const KeyIcon = () => <FontAwesomeIconWrapper icon={faKey} />
export const InboxIcon = () => <FontAwesomeIconWrapper icon={faInbox} />
export const ExclamationTriangleIcon = () => <FontAwesomeIconWrapper icon={faExclamationTriangle} />
