import React from "react"

const InvoiceExternalOrderField = ({ record = {} }) => (
  <span>
    #{record.external_invoice_number} - Order id {record.external_order_id}, Faktura id {record.external_invoice_id}
  </span>
)
InvoiceExternalOrderField.defaultProps = { label: "Tripletex" }

export default InvoiceExternalOrderField
