
import PageBreadcrumb from "components/PageBreadcrumb"
import React from "react"
import EmailTemplateEdit from "./EmailTemplateEdit"

const sanitizeEditProps = ({hasList, hasEdit, hasCreate, ...rest}) => rest

export default ({ ...props }) => (
  <>
    <PageBreadcrumb {...props} />
    <EmailTemplateEdit {...sanitizeEditProps(props)} />
  </>
)
