import React from "react"
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from "react-admin"

const SubscriptionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Offer" source="offer_id" reference="offer" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="reference" />
      <NumberInput source="amount" />
      <TextInput source="comments" />
    </SimpleForm>
  </Create>
)

export default SubscriptionCreate
