import React from "react"
import { List, Datagrid, TextField, ReferenceField, ReferenceManyField, SingleFieldList } from "react-admin"
import InvoiceExternalOrderField from "./InvoiceExternalOrderField"
import DateTimeField from "components/DateTimeField"
const InvoiceList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Konto" source="account_id" reference="account" link={false}>
        <TextField source="company" />
      </ReferenceField>

      <TextField source="orgnr" />
      <TextField source="reference" />

      <ReferenceManyField label="Tripletex" reference="invoice_extended" target="invoice_id">
        <SingleFieldList>
          <InvoiceExternalOrderField source="external_order_id" />
        </SingleFieldList>
      </ReferenceManyField>

      <DateTimeField source="created" />
    </Datagrid>
  </List>
)

export default InvoiceList
