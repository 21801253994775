import React from "react"
import Typography from "@material-ui/core/Typography"
import { translate } from "react-admin"
import { connect } from "react-redux"
import { refreshView as refreshViewAction } from "ra-core"
import { withStyles } from "@material-ui/core/styles"
import dataProvider from "dataProvider"
import { showNotification, fetchStart, fetchEnd, GET_LIST } from "react-admin"
import { dateTimeFormatter } from "components/utils"

const styles = {
  aside: {
    width: 200,
    margin: "1em",
    flexDirection: "row",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& h2": { fontSize: "1rem" },
    "& p": {
      display: "flex",
      fontSize: "0.8rem",
      opacity: "0.7",
      marginTop: "0.5em"
    },
    "& span:first-child": { flex: 1 }
  }
}

class RevisionInfoAside extends React.Component {
  constructor(props) {
    super(props)
    this.state = { logs: [] }
  }

  handleShowAuditLog = () => {
    const { showNotification, fetchStart, fetchEnd, entity, record } = this.props
    if (record) {
      const { id } = record
      fetchStart()
      dataProvider(GET_LIST, "audit_log", {
        pagination: { page: 1, perPage: 25 },
        filter: { entity: entity, entity_id: id },
        sort: { order: "desc", field: "id" }
      })
        .then(({ data }) => {
          this.setState({ logs: data })
        })
        .catch(() => {
          showNotification("Error: comment not approved", "warning")
        })
        .finally(fetchEnd)
    }
  }

  renderLogs = () => {
    const { logs = [] } = this.state
    return logs.map(l => (
      <li>
        <span>{l.operation}</span>
        <span>{l.payload}</span>
      </li>
    ))
  }
  render() {
    const { classes, record, translate, resource } = this.props
    if (!record) return null

    return (
      <div className={classes.aside}>
        <Typography>{translate(`resources.${resource}.name`, 1)}</Typography>
        <Typography>
          <span>{translate("kb.created")}:</span> <span>{dateTimeFormatter(record.created)}</span>
        </Typography>
        <Typography>
          <span>{translate("kb.updated")}:</span> <span>{dateTimeFormatter(record.updated)}</span>
        </Typography>
        {/* <span onClick={this.handleShowAuditLog}>Show log</span>
            <ul>
                {this.renderLogs()}
            </ul> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  refreshView: refreshViewAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(translate(RevisionInfoAside)))
