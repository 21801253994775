import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import inflection from "inflection"
import compose from "recompose/compose"
import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"
import { getResources, translate } from "ra-core"
import Typography from "@material-ui/core/Typography"

import { MenuList } from "@material-ui/core"
import { DashboardMenuItem, MenuItemLink, Responsive } from "react-admin"
import PersonIcon from "@material-ui/icons/Group"
import SettingsIcon from "@material-ui/icons/Settings"
import MonetizationOn from "@material-ui/icons/MonetizationOn"
import ListIcon from "@material-ui/icons/List"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"

const styles = () => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginRight: "1em"
  },
  root: {
    " & > div": {
      paddingLeft: 0
    },
    width: "100%"
  },
  nested: {
    " & svg": {
      width: "20px !important",
      height: "20px"
    }
  },
  folder: {
    paddingBottom: "0.5em"
  },
  folderbar: {
    padding: "0.5em",
    paddingLeft: "16px",
    textTransform: "uppercase",
    fontWeight: "bold"
  }
})

const folders = [
  {
    id: "account",
    icon: <PersonIcon />,
    label: "Kunder",
    resources: ["account", "user_list", "user_auth", "domain", "account_activation_queue_report"]
  },
  {
    id: "invoice",
    icon: <MonetizationOn />,
    label: "Fakturer",
    resources: ["invoice_item_pending", "invoice", "invoice_pending"]
  },
  {
    id: "product",
    icon: <ShoppingCartIcon />,
    label: "Produkter",
    resources: ["product_list", "offer_list"]
  },
  {
    id: "setting",
    icon: <SettingsIcon />,
    label: "Innstillinger",
    resources: ["vat", "period_unit", "email_template", "role"]
  },
  {
    id: "sso",
    icon: <SettingsIcon />,
    label: "Single sign on",
    resources: ["client"]
  },
  {
    id: "log",
    icon: <ListIcon />,
    label: "Logg",
    resources: ["subscription_log", "email_message", "email_status", "event_log", "audit_log", "account_account_extended_report"]
  }
]

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label
          })
        : inflection.humanize(inflection.pluralize(resource.name))
  })

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      open: null
    }
  }

  handleClick = folder => {
    this.setState(state => ({ open: state.open !== folder ? folder : "" }))
  }

  isOpen = folderId => {
    if (this.state.open) {
      return folderId === this.state.open
    }
    const { pathname } = this.props
    const pathname_resource = pathname.substring(1)
    var resourceMap = folders.reduce((accumulator, folder) => {
      folder.resources.forEach(r => {
        accumulator[r] = folder.id
      })
      return accumulator
    }, {})
    return resourceMap[pathname_resource] === folderId
  }

  render() {
    const { classes, className, dense, hasDashboard, onMenuClick, open, resources, translate, logout, ...rest } = this.props

    return (
      <div className={classnames(classes.main, className)} {...rest}>
        {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
        <MenuList disablePadding component="nav">
          {folders.map(folder => {
            return (
              <div key={`collapse_${folder.id}`} className={classes.folder}>
                {open && <Typography className={classes.folderbar}>{folder.label}</Typography>}
                <MenuList disablePadding component="nav">
                  {resources
                    .filter(r => r.hasList)
                    .filter(r => folder.resources.indexOf(r.name) >= 0)
                    .map(resource => {
                      //const icon = typeof resource.icon === 'string' ? <resource.icon /> : resource.icon
                      const icon = <resource.icon />
                      return (
                        <MenuItemLink
                          key={resource.name}
                          to={`/${resource.name}`}
                          leftIcon={icon ? icon : null}
                          primaryText={translatedResourceName(resource, translate)}
                          onClick={onMenuClick}
                          className={classes.nested}
                          dense={dense}
                        />
                      )
                    })}
                </MenuList>
              </div>
            )
          })}
        </MenuList>

        <Responsive xsmall={logout} medium={null} />
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
  resources: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
}

Menu.defaultProps = {
  onMenuClick: () => null
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname // used to force redraw on navigation
})

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index] // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open
    }
  ),
  withStyles(styles)
)

export default enhance(Menu)
