// in EmailTemplates.js
import React from "react"
import { List, Datagrid, TextField } from "react-admin"

const EmailTemplateList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="subject" />
      <TextField source="event" />
      <TextField source="bcc" />
    </Datagrid>
  </List>
)

export default EmailTemplateList
