import React from "react"
import { Show, SimpleShowLayout, TextField } from "react-admin"
import DateTimeField from "components/DateTimeField"
const EmailMessageTitle = ({ record }) => (record ? `"${record.subject}"` : "")

const EmailMessageShow = props => (
  <Show title={<EmailMessageTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="email_template_id" />
      <TextField source="to" />
      <TextField source="subject" />
      <TextField source="body" />
      <TextField source="params" />
      <TextField source="out_message" />
      <TextField source="status" />
      <DateTimeField source="created" />
    </SimpleShowLayout>
  </Show>
)

export default EmailMessageShow
