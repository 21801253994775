// in Clients.js
import React from "react"
import { SimpleForm, Edit, TextInput, SelectInput } from "react-admin"
import PageFieldset from "components/PageFieldset"

const Title = ({ record }) => record.name || ''

const ClientEdit = props => (
  <Edit undoable={false} {...props} title={<Title />}>
    <SimpleForm {...props} redirect="edit">
      <PageFieldset>
        <TextInput source="name" type="text" />
        <SelectInput
          source="method"
          choices={[
            { id: "implicit", name: "implicit (spa app)" },
            { id: "code", name: "code (backend app)" }
          ]}
          allowEmpty
        />

        <TextInput source="home_url" type="text" />
        <TextInput source="callback_url" type="text" />
        <TextInput source="login_url" type="text" />
        <TextInput source="logout_url" type="text" />
        <TextInput source="error_url" type="text" />
        <TextInput source="return_url" type="text" />
        <TextInput source="uuid" type="text" />
        <TextInput source="secret" type="text" />
      </PageFieldset>
    </SimpleForm>
  </Edit>
)

export default ClientEdit
