// in Users.js
import React from "react"

import { Create, ReferenceInput, DateInput, TextInput, AutocompleteInput } from "react-admin"

import PageForm from "components/PageForm"
import PageFieldset from "components/PageFieldset"

const UserAuthCreate = props => (
  <Create {...props}>
    <PageForm>
      <PageFieldset>
        <TextInput source="email" />
        <TextInput source="token" />
        <DateInput source="last_login" />
        <DateInput source="activated" />
        <ReferenceInput source="user_id" reference="user" filterToQuery={searchText => ({ email: searchText })} allowEmpty>
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
      </PageFieldset>
    </PageForm>
  </Create>
)

export default UserAuthCreate
